.courses-table * {
  margin: 0;
  padding: 0;
}
.courses-table {
  position: relative;
  color: #000;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen,
    Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif;
  font-family: var(--font-family-1);
  font-size: 0.75rem;
  font-size: var(--font-size-12-24);

  color: #000;
  margin-left: clamp(
    1.5625rem,
    1.0315533980582523rem + 2.26537216828479vw,
    3.75rem
  );
  margin-right: clamp(
    1.25rem,
    0.6432038834951456rem + 2.5889967637540456vw,
    3.75rem
  );
  padding-left: clamp(
    0.46875rem,
    -0.6310679611650485rem + 4.692556634304207vw,
    5rem
  );
  padding-right: clamp(
    0.78125rem,
    -0.5992111650485437rem + 5.889967637540453vw,
    6.46875rem
  );
  padding-top: clamp(
    0.75rem,
    0.5072815533980582rem + 1.035598705501618vw,
    1.75rem
  );
  padding-bottom: 1.5rem;
  display: block;
  background: transparent;
  text-align: left;
}
.courses-table::before {
  content: "";
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  background: linear-gradient(
    180deg,
    rgba(255, 158, 210, 0.48) 0%,
    rgba(241, 213, 215, 0.1) 100%
  );
  opacity: 0.48;
}
.courses-table :is(tr, td) {
  z-index: 4;
  position: relative;
}
.courses-table tr {
  border-top: 2px solid #681e4699;
}

.courses-table :is(td, th) {
  padding: clamp(
      0.875rem,
      0.7839805825242718rem + 0.3883495145631068vw,
      1.25rem
    )
    0;
}
.courses-table > thead {
  background-color: rgba(255, 158, 210, 0.2);
}
.courses-table > tbody > tr {
  border-right: clamp(0rem, -1.6990291262135921rem + 7.249190938511327vw, 7rem)
    transparent solid;
}
/* .courses-table > tbody > tr > :nth-child(2) {
  padding-right: clamp(
    1.5625rem,
    -0.1516990291262137rem + 7.313915857605179vw,
    8.625rem
  );
} */
.courses-table > tbody > tr > td {
  padding: 5px 10px;
}
.courses-table tbody > tr > :nth-child(1) > strong {
  padding-right: 0.2rem;
}
.privateInstructionsGeneralIcons {
  width: 60px;
  height: 60px;
}
.privateInstructionsAgeIcons {
  width: 60px;
  height: 60px;
}
.courses-table > li {
  font-size: x-large;
}
.fontSize25 {
  font-size: 25px;
}
.textAlignCenter {
  text-align: center;
}

thead > tr > th,
td {
  text-align: center;
}

.ageColumn {
  font-size: 20px;
  display: table-cell;
}

.ageColumnDigit {
  font-size: 30px;
  width: 100%;
}

@media (max-width: 768px) {
  .fontSize25 {
    font-size: 20px;
  }

  .ageColumnDigit {
    font-size: 25px;
  }
}

@media (max-width: 500px) {
  .privateInstructionsGeneralIcons,
  .privateInstructionsAgeIcons {
    width: 40px;
    height: 40px;
  }
  .ageColumn {
    font-size: 17px;
  }
  .ageColumnDigit {
    font-size: 20px;
  }
}
