.teamMemberCardIconsWrapper {
  align-self: flex-start;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: column nowrap;
  margin: auto;
  background-color: #ffdcdf;
  padding: 10px;
  border-radius: 8px;
  font-size: x-large;
}

.iconsWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: row nowrap;
  gap: 10px;
  margin: auto;
  font-size: x-large;
}

.shortDescriptionParagraph {
  text-align: center;
  font-size: large;
  margin: 2px;
}

.teamMemberCardIconsSpace {
  width: 20px;
}

.iconsWrapper > .smallIconGroup > svg {
  width: 45px;
  height: 45px;
}

.teamMemberCardIconsWrapperModal {
  font-size: 40px;
}

.teamMemberCardIconsWrapperModal > .iconsWrapper > .smallIconGroup > svg {
  width: 80px;
  height: 80px;
}

.smallIconGroup {
  display: flex;
  gap: 3px;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  font-size: inherit;
}

@media (max-width: 1240px) {
  .teamMemberCardIconsWrapper {
    max-width: 85%;
  }

  .teamMemberCardIconsWrapperModal > .iconsWrapper > .smallIconGroup > svg {
    width: 55px;
    height: 55px;
  }
}

@media (max-width: 1020px) {
  .iconsWrapper {
    flex-flow: row wrap;
  }
}

@media (max-width: 970px) {
  .iconsWrapper {
    flex-flow: row wrap;
  }
}
