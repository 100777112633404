.artis-news {
  background-color: rgba(244, 206, 217, 1);
}
/* .artis-news .book-class > h2 {
  font-size: calc(.5* var(--font-size-34-84));
  margin-bottom: calc(.5*var(--h2-margin-bottom));
}
.artis-news .book-class > a {
  font-size: calc(.5* var(--font-size-12-36));
 padding: calc(.6*var(--link-padding));
} */