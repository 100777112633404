.book-class {
  text-align: center;
  margin: clamp(2.375rem, 1.8895631067961165rem + 2.071197411003236vw, 4.375rem)
    auto;
  position: relative;
  width: fit-content;
}
.book-class::before {
  background: #f27180 0% 0% no-repeat padding-box;
  border-radius: 250px;
  opacity: 0.3;
  filter: blur(50px);
  content: "";
  position: absolute;
  top: 0;

  left: 0;
  width: 100%;
  height: 150%;
  z-index: 0;
}
.book-class > h2 {
  position: relative;
  font-size: var(--font-size-34);
  font-size: calc(.7*var(--font-size-34-84));
  
  font-family: var(--font-family-1);
  font-weight: 600;
  color: #812990;
  --h2-margin-bottom: clamp(2rem,
    1.7269417475728155rem + 1.1650485436893203vw,
    3.125rem);
  margin-bottom: calc(.8*var(--h2-margin-bottom));
}


.book-class > a {
  --scaling-level: 0.6;
  --link-padding: calc(var(--scaling-level) * clamp(0.875rem,
    0.6474514563106796rem + 0.9708737864077671vw,
    1.8125rem)) calc(var(--scaling-level) *clamp(1.6875rem, 1.4144417475728155rem + 1.1650485436893203vw, 2.8125rem));
  position: relative;
  padding: var(--link-padding);
  background-color: #812990;
  box-shadow: 0px 12px 14px #81299099;
  color: #fff;
  text-decoration: none;
  display: inline-block;
  font-size: 0.75rem;
  font-size: calc(.8*var(--font-size-12-36));
     
}
