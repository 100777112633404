.guest-menu,
.guest-menu .ant-menu-sub {
  background-color: #681e46 !important;
  border-radius: 1.3rem !important;
  -webkit-border-radius: 1.3rem !important;
  -moz-border-radius: 1.3rem !important;
  -ms-border-radius: 1.3rem !important;
  -o-border-radius: 1.3rem !important;
  user-select: none;
}

.ant-menu-submenu-title {
  display: flex !important;
  justify-content: center;
  align-items: center;
}

.guest-menu .ant-menu-title-content {
  font-size: 18px;
}

@media screen and (max-width: 768px) {
  .guest-menu .ant-menu-title-content {
    font-size: 16px;
  }
}

.guest-menu .ant-menu-sub .ant-menu-submenu-title,
.ant-menu-item {
  color: #fff !important;
}

.ant-menu-overflow-item.ant-menu-submenu.ant-menu-submenu-horizontal::after {
  display: none !important;
}

.guest-menu .ant-menu-sub .ant-menu-submenu-title:active,
.ant-menu-item:active,
.ant-menu-item-selected {
  background-color: #681e46 !important;
}

.guest-menu-title {
  display: inline-flex;
  align-items: center;
  gap: 8px;
}
