* {
  margin: 0;
  padding: 0;
  scroll-behavior: smooth;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

body {
  font-family: "Ubuntu", sans-serif;
}

/* a {
  color: white;
}

body {
  color: white;
} */
.overflowBody {
  overflow-y: auto;
  overflow-x: hidden;
  overflow: hidden;
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #681e4680;
  border-radius: 15px;
}

.bg-purple {
  background-color: #681e46;
  border-color: #681e46;
  outline: none !important;
  box-shadow: none !important;
}
.bg-purple:hover,
.bg-purple:focus {
  background-color: #5a1a3c;
  border-color: #681e46;
  outline: none !important;
  box-shadow: none !important;
}
.bg-purple:active {
  background-color: #4b1532;
  border-color: #681e46;
  outline: none !important;
  box-shadow: none !important;
}
.text-purple {
  color: #681e46 !important;
}
.border-purple {
  border-color: #681e46 !important;
}
.fw-medium {
  font-weight: 500;
}

/* ----------------------------------------------------------------------------------------- */
a[data-toggle="collapse"] {
  position: relative;
}
a[aria-expanded="true"] {
  opacity: 1;
}
ul ul a {
  font-size: 0.9em !important;
  padding-left: 30px !important;
  background: #6d7fcc;
}

a.download {
  background: #fff;
  color: #7386d5;
}

a.article,
a.article:hover {
  background: #6d7fcc !important;
  color: #fff !important;
}

#sidebarCollapse {
  display: none;
}

@media (max-width: 768px) {
  #sidebarCollapse {
    display: inline-block;
  }
}

.css-2tol6g-singleValue {
  background-color: transparent !important;
}
