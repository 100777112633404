.teamProfileExtended {
  background-color: white;
  min-width: 300px;
  min-height: 200px;
  max-height: 90%;
  overflow: scroll;
  width: 80%;
  margin: auto;
  border-radius: 15px;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  flex-flow: column nowrap;
  gap: 15px;
  padding: 15px;
  background-color: antiquewhite;
  font-size: large;
  text-indent: 20px;
  /* box-sizing: border-box; */
}

.backButton {
  position: relative;
  bottom: 15px;
  right: 15px;
  width: 60px;
  background-color: rgb(194, 163, 222);
  border: 1px solid rgb(168, 140, 192);
  color: white;
  padding: 4px 8px;
  border-top-left-radius: 15px;
  border-bottom-right-radius: 15px;
}

@media (max-width: 500px) {
  .teamProfileExtended {
    width: 90%;
  }
}
