.home-gallery {
  position: relative;
  margin-bottom: 0;
  width: 100%;
  max-width: 100%;
  overflow: hidden;
}
.home-gallery .gallery-images {
  display: flex;
  overflow-x: scroll;
  overflow-y: hidden;
  scroll-behavior: smooth;
  scroll-snap-type: x mandatory;
  margin: 0;
  position: relative;
  height: 100%;
  max-height: 100vh;
  align-items: flex-start;
}

.home-gallery::after {
  content: "";
  top: 0;
  right: 0;
  position: absolute;
  width: 14%;
  height: 100%;
  background: transparent linear-gradient(90deg, #ffffff00 0%, #f6f2f44d 100%)
    0% 0% no-repeat padding-box;
  z-index: 2;
}

.home-gallery .gallery-images::-webkit-scrollbar {
  display: none;
}
.home-gallery .gallery-images picture img {
  width: 100vw;
  max-width: var(--max-width-1);
  aspect-ratio: 1.7/1;
  scroll-snap-align: start;
}
.home-gallery form {
  position: absolute;
  bottom: 0.6875rem;

  left: 0;
  width: 100%;
  background: transparent;
  text-align: center;
  display: flex;
  gap: 0.625rem;
  justify-content: center;
  align-items: center;
  z-index: 2;
}

.home-gallery form input[type="radio"] {
  --button-size: clamp(
    0.375rem,
    0.2233009708737864rem + 0.6472491909385114vw,
    1rem
  );
  --button-size-checked: calc(1.25 * var(--button-size));
  --button-border-width: clamp(
    0.0625rem,
    0.04733009708737864rem + 0.06472491909385113vw,
    0.125rem
  );
  z-index: 4;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  cursor: pointer;
  border: var(--background-primary-color) 1px solid;
  border-radius: 50%;
  width: var(--button-size);
  height: var(--button-size);
  transition: width 1s ease-in-out, height 1s ease-in-out;
  border-width: var(--button-border-width);
}
.home-gallery form input:checked {
  border: 1px var(--background-primary-color) solid;
  background-color: var(--background-primary-color);
  width: var(--button-size-checked);
  height: var(--button-size-checked);
}

.promotion {
  position: absolute;
  top: 50%;
  left: 2%;
  transform: translateY(-50%);
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  -o-transform: translateY(-50%);
}

.promotion-images {
  display: flex;
  flex-wrap: wrap;
  gap: 24px;
}

.promotion-contact {
  width: 100%;
  margin-top: 24px;
  padding: 16px;
  background-color: #f9f9f9;

  border-radius: 12px;
  -webkit-border-radius: 12px;
  -moz-border-radius: 12px;
  -ms-border-radius: 12px;
  -o-border-radius: 12px;
}

@media screen and (max-width: 1280px) {
  .promotion-contact {
    display: none;
  }
}

.promotion-contact h4 {
  color: #681e46;
  text-align: center;
}

.promotion-buttons {
  display: flex;
  justify-content: center;
  gap: 24px;
  margin-top: 12px;
}

.raisingTalents {
  width: 720px;
  gap: 10px;
  padding: 5px;
  box-sizing: border-box;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: flex-end;

  transform: translate(0, -100%);
}

@media (min-width: 1066px) {
  .home-gallery form {
    gap: 1.25rem;
    bottom: 1.5rem;
  }
}

@media (max-width: 1430px) {
  .raisingTalentsWrapper {
    bottom: 130px;
  }
}

@media (max-width: 1365px) {
  .raisingTalentsWrapper {
    bottom: 120px;
  }
}

@media (max-width: 1300px) {
  .raisingTalentsWrapper {
    bottom: 110px;
  }
}

@media (max-width: 1230px) {
  .raisingTalentsWrapper {
    bottom: 100px;
  }
}

@media (max-width: 1160px) {
  .raisingTalentsWrapper {
    bottom: 90px;
  }
}
@media (max-width: 1100px) {
  .raisingTalentsWrapper {
    bottom: 85px;
  }
}

@media (max-width: 1060px) {
  .raisingTalentsWrapper {
    bottom: 70px;
  }
}

@media (max-width: 990px) {
  .raisingTalentsWrapper {
    bottom: 58px;
  }
}

@media (max-width: 980px) {
  .raisingTalentsWrapper {
    display: none;
  }
}
