.home-nav-button {
  border: none;
  height: 50%;
  background: transparent;
}
.home-nav-button:focus {
  outline: none;
}

.home-nav-button img {
  height: 100%;
  width: auto;
}
.home-nav-secondary-list {
  display: none;
  position: absolute;
  text-decoration: none;
  z-index: 5;
  top: 105%;
  right: clamp(2.125rem, 9.06vw, 8.75rem);
  background: linear-gradient(
    90deg,
    rgba(255, 228, 243, 1) 0%,
    rgba(230, 120, 134, 1)
  );
  border-radius: 8px 0px 0px 8px;
  /* width: clamp(8.81rem, 35vw, 24.88rem); */
  padding: 11px 18px;
  margin: 0;
}

.home-nav-secondary-list a {
  box-sizing: border-box;
  color: var(--primary-color);
  /* original font-size 12-28 */
  font-size: var(--font-size-12-24);
  text-decoration: none;
  font-family: var(--font-family-1);
}
.home-nav-secondary-list.active {
  display: flex;
  flex-direction: column;
}
/* screens bigger than 1066px */
@media (min-width: 1066px) {
  .home-nav-secondary-list {
    border-radius: 30px 0px 0px 30px;
    padding: 2rem 3.125rem;
  }
}

.nav-buttons {
  display: flex;
  align-items: center;
  height: 100%;
  gap: 8px;
}

.login-btn-wrapper {
  width: 90px;
  height: 80%;
}

.login-btn-wrapper button {
  font-size: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.menuIcon {
  width: 25px;
  height: 25px;
}

@media screen and (min-width: 468px) {
  .nav-buttons {
    gap: 16px;
  }

  .login-btn-wrapper {
    width: 120px;
    height: 90%;
  }

  .login-btn-wrapper button {
    font-size: 12px;
  }
}

@media screen and (min-width: 768px) {
  .login-btn-wrapper {
    width: 150px;
  }

  .login-btn-wrapper button {
    font-size: 14px;
  }
}

@media screen and (min-width: 1066px) {
  .nav-buttons {
    height: 100%;
    gap: 56px;
  }

  .login-btn-wrapper {
    width: 200px;
    height: 45px;
  }

  .login-btn-wrapper button {
    font-size: 16px;
  }
}
