main.our-faculty {
  background: var(--background-color-1);
  padding: 0;
  margin: 0;
  /* variables */
  --color-1: #812990;
  --background-color-1: #f4ced9;
  --max-width-1: 2066px;
}
.our-faculty-container {
  max-width: var(--max-width-1);
  margin: 0 auto;
  padding: 0;
}
.faculty-container {
  padding: clamp(
      2.8125rem,
      2.1298543689320386rem + 2.912621359223301vw,
      5.625rem
    )
    clamp(2.5rem, 0.9830097087378642rem + 6.472491909385113vw, 8.75rem);
}

.faculty-container > h2 {
  /* font-family: var(--font-family-2); */
  color: var(--color-1);
  font-weight: bold;
  font-size: 2.125rem;
  font-size: clamp(
    2.125rem,
    1.366504854368932rem + 3.2362459546925564vw,
    5.25rem
  );
  margin: 0;
  padding: 0;
  margin-bottom: clamp(
    1rem,
    0.2566747572815534rem + 3.171521035598705vw,
    4.0625rem
  );
}
.faculty-container > h2:nth-of-type(2) {
  margin-top: clamp(
    3.6875rem,
    1.5485436893203883rem + 9.12621359223301vw,
    12.5rem
  );
}
.faculty-members-container {
  display: grid;
  grid-template-columns: 1fr;
  gap: clamp(2rem, 0.6650485436893203rem + 5.6957928802589vw, 7.5rem);
  margin: 0;
  padding: 0;
}

.teamMemberProfiles {
  background: var(--background-color-1);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-flow: row wrap;
  gap: 16px;
  margin-top: 15px;
}

.alignCenter {
  text-align: center;
}
