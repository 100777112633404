.achievements-container {
    font-family: Arial, sans-serif;
    padding: 20px;
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
}

h1 {
    font-size: 24px;
    text-align: center;
    margin-bottom: 20px;
    color: #333;
}

.timeline-container {
    display: flex;
}

.timeline {
    width: 15px;
    position: relative;
    padding-right: 10px;
    /*border-right: 2px solid #f0f0f0;*/

    @media (min-width: 768px) {
        width: 31px;
    }
}

.timeline-month {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 30px;
}

.timeline-dot {
    width: 10px;
    height: 10px;
    background-color: #ff69b4;
    border-radius: 50%;
    margin-bottom: 10px;
}

.timeline-line {
    width: 2px;
    height: 160px;
    background-color: #f0f0f0;
}

.achievements-list {
    flex: 1;
    padding-left: 0px;

    @media (min-width: 768px) {
        padding-left: 20px;
    }
}

.month-section {
    margin-bottom: 30px;
}

.month-title {
    font-size: 18px;
    color: #ff69b4;
    margin-bottom: 10px;
}

.achievement-card {
    border: 1px solid #f0f0f0;
    border-radius: 8px;
    padding: 15px;
    background-color: #fff;
    transition: box-shadow 0.3s;
    margin-bottom: 15px;
}

.achievement-card:hover {
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
}

.achievement-card-details {
    border: 1px solid #f0f0f0;
    border-radius: 8px;
    padding: 25px;
    background-color: #fff;
    transition: box-shadow 0.3s;
    margin-bottom: 15px;
}

.achievement-card-details:hover {
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
}

.achievement-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}

.achievement-header h4 {
    font-size: 16px;
    color: #333;
    margin: 0;
}

.achievement-date {
    font-size: 14px;
    color: #888;
}

.achievement-body {
    margin-bottom: 10px;
}

.achievement-body p {
    margin: 5px 0;
    font-size: 14px;
    color: #555;
}

.achievement-status {
    font-size: 14px;
    font-weight: bold;
    border-radius: 12px;
    padding: 4px 8px;
    display: flex;
    align-items: center;
}

.achievement-status.reviewed {
    color: #ff69b4;
    background-color: #ffeef8;
}

.achievement-status.verified {
    color: #4caf50;
    background-color: #e8f5e9;
}

.achievement-actions {
   display: flex;
    justify-content: space-between;
    margin-top: 30px;

    @media (min-width: 768px) {
        margin-top: 10px;
    }
}

.view-file-btn {
    background-color: #fff;
    color: #fff;
    padding: 6px 12px;
    font-size: 14px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
    border: none;
}

.view-file-btn-main {
    background-color: #681e46;
    color: #fff;
    border: none;
    padding: 6px 12px;
    font-size: 14px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s;
    margin-bottom: 20px;
    margin-left: auto;

    @media (min-width: 768px) {
        padding: 8px 16px;
        font-size: 16px;
        margin-bottom: 0px;
    }
}

.view-file-btn:hover {
    background-color: #ff4081;
}

.full-width {
    width: 100%;
    input {
        width: 100% !important;
    }

    textarea {
        width: 100% !important;
    }
}
.close-btn-container {
    top: 20px;
    right: 20px;
}

button.close {
    padding: 3px 7px;
    font-size: 20px;
    border-radius: 30px;
    height: 40px;
    width: 40px;
    background-color: #681e460d;
    color: #707070;

    &:hover {
        background-color: #5a1a3d1d;
    }

    &:active {
        background-color: #42132d2d;
    }
}

.icon-color {
    color: #681e46;
}

.icon-md {
    font-size: 1.5rem;
}

.modal-content {
    overflow: hidden !important;
}

.form-btn {
    background-color: white;
    border: none;
}

.btn-success-custom {
    color: #681e46;
    font-weight: 600;
    font-size: 22px !important;
}

.btn-success-custom:hover {
    border: none !important;
}

.btn-cancel-custom {
    color: #6c757d;
    font-size: 22px !important;
    font-weight: 600;
}

.line-btn {
    height: 44px;
    width: 2px;
    background-color: #6c757d;
}

.title-profile {
    margin-left: 15px;
    @media (min-width: 768px) {
        margin-left: 0px;
    }
}

.icon-profile {
    margin-left: 0px;
    justify-content: flex-start;
    align-items: center;
    @media (min-width: 768px) {
        margin-left: 20px;
        justify-content: space-between;
    }
}
