.home-nav {
  width: 100%;
  height:  var(--home-nav-height);
  background: transparent linear-gradient(270deg, #ffe4f3 0%, #ffffff 100%) 0%
    0% no-repeat padding-box;
  box-shadow: 0px 4px 14px #ddddddc2;
  border-radius: 0px 0px 8px 8px;
  opacity: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: clamp(0.2rem, 0.005825242718446588rem + 0.8284789644012946vw, 1rem) clamp(2.125rem, 9.06vw, 8.75rem);
  z-index: 5;
  position: sticky;
  top: 0;
  left: 0;
  margin: 0;
}
.home-nav img {
  height: 100%;
  width: auto;
}
.home-nav-logo {
  margin: 0;
  height: 100%;
}
@media (min-width: 1066px) {
  .home-nav {
    border-radius: 0 0 3rem 3rem;
  }
}

.nav-left-column {
  height: 100%;

  display: flex;
  align-items: center;
  gap: 56px;
}