.calendar {
  
  margin-left: auto;
}
@media (max-width: 1066px) {
  .calendar {
    margin: clamp(5rem, 2.2865412445730824rem + 11.577424023154848vw, 10rem) 0;
  }

}

.calendar > .rbc-calendar {
  min-height: 40rem;
  font-size: 0.9rem;
  max-width: 50rem;
  margin-left: auto;
}

/* tool bar of rbc-calendar */
.calendar .rbc-calendar > .rbc-toolbar {
  flex-wrap: wrap;
  justify-content: space-between;
  gap: 1rem;
  margin-bottom: 1rem;
}
.calendar .rbc-calendar > .rbc-toolbar > .rbc-btn-group-first {
  display: flex;
  gap: 0.3rem;
}
.calendar .rbc-calendar > .rbc-toolbar > .rbc-btn-group-first > button {
  background-color: #000;
  border-radius: 4px;
  color: #fff;
  font-size: 1rem;
  text-align: center;
  vertical-align: middle;
  padding: 0.5rem 1rem;
  line-height: 1.2rem;
  border: hidden;
  font-weight: bold;
  transition: all 200ms ease-in-out;
}
.calendar > .rbc-calendar > .rbc-toolbar button:hover {
  background-color: #007aff;
  color: #fff;
}
.calendar .rbc-calendar > .rbc-toolbar > .rbc-btn-group-first > :nth-child(3) {
  font-weight: 400;
}
.calendar > .rbc-calendar > .rbc-toolbar > .rbc-toolbar-label {
  color: var(--background-secondary-color);
  font-size: 2rem;
  max-width: fit-content;
  padding: 0;
  margin: 0;
}
.calendar > .rbc-calendar > .rbc-toolbar > .rbc-btn-group-second {
  display: flex;
  gap: 0.3rem;
}
.calendar > .rbc-calendar > .rbc-toolbar > .rbc-btn-group-second > button {
  background-color: #ddd;
  border-radius: 4px;
  padding: 0.5rem 1rem;
  line-height: 1.2rem;
  border: hidden;
  font-size: 1rem;
  color: #666;
}
.calendar
  > .rbc-calendar
  > .rbc-toolbar
  > .rbc-btn-group-second
  > button.rbc-active {
  background-color: #007aff;
  color: #fff;
}

/* month view in the calendar */
.calendar > .rbc-calendar > :is(.rbc-month-view) {
  color: #000;
  font-size: 1rem;
  font-weight: bold;
  border: hidden;
}
.calendar > .rbc-calendar > :is(.rbc-month-view) > :is(.rbc-month-header) {
  background-color: #000;
  color: #fff;
  margin-bottom: 2rem;
}
.calendar > .rbc-calendar > .rbc-month-view > .rbc-month-header > .rbc-header {
  padding: 0.7rem 0;
  font-weight: bold;
}
.calendar > .rbc-calendar > .rbc-month-view .rbc-month-row {
  text-align: left;
  border: 1px solid #ddd;
  max-height: 8rem;
}
.calendar > .rbc-calendar > .rbc-month-view .rbc-today {
  background-color: #f27180;
}
.calendar > .rbc-calendar > .rbc-month-view .rbc-off-range-bg {
  opacity: 0.7;
  background-color: transparent;
}

/* week view in the calendar */
.calendar > .rbc-calendar > .rbc-time-view {
  color: #000;
  font-size: 1rem;
  font-weight: 400;
}
.calendar > .rbc-calendar > .rbc-time-view {
  border: hidden;
}
.calendar > .rbc-calendar > .rbc-time-view .rbc-time-header {
}
.calendar > .rbc-calendar > .rbc-time-view .rbc-time-header-content {
  border: hidden;
}
.calendar > .rbc-calendar > .rbc-time-view .rbc-time-header-cell {
  background-color: #000;
  color: #fff;
  font-size: 1rem;
  font-weight: 600;
  vertical-align: middle;
  margin-bottom: 4rem;
  height: 3.5rem;
}
.calendar > .rbc-calendar > .rbc-time-view .rbc-time-header-cell .rbc-header {
  border: hidden;
  height: 100%;
  border-right: solid 1px #ddd;
  display: flex;
  align-items: center;
  justify-content: center;
}
.calendar > .rbc-calendar > .rbc-time-view .rbc-time-header-cell .rbc-header a {
  overflow: unset;
  text-overflow: auto;
  width: 100%;
}
.calendar
  > .rbc-calendar
  > .rbc-time-view
  .rbc-time-header-cell
  .rbc-header
  a
  span {
  width: 5px;
  margin: auto;
  display: table;
  text-align: center;
  white-space: pre-wrap;
  word-wrap: break-word;
}

.calendar > .rbc-calendar > .rbc-time-view .rbc-today {
  background-color: #f27180;
}
.calendar > .rbc-calendar > .rbc-time-view .rbc-time-header-gutter {
  border: hidden;
  position: relative;
  padding: 0;
}
.calendar > .rbc-calendar > .rbc-time-view .rbc-time-header-gutter::after {
  width: 100%;
  height: 3rem;
  content: "All Day";
  position: absolute;
  bottom: 0;
  left: 0;
  border-top: solid 1px #ddd;
  border-left: solid 1px #ddd;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
}
.calendar > .rbc-calendar > .rbc-time-view .rbc-allday-cell {
  border-top: 1px solid #ddd;
  border-right: 1px solid #ddd;
  border-left: 2px solid #ddd;
  height: 3rem;
  box-sizing: border-box;
}
.calendar > .rbc-calendar > .rbc-time-view .rbc-time-content {
  border: solid 1px #ddd;
}
.calendar > .rbc-calendar > .rbc-time-view .rbc-time-content .rbc-time-slot {
  border: hidden;
}
