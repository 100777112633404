.calendar-container {
  padding: var(--section-padding);
}
.calendar-container >  .second-heading {
    margin: clamp(0.5rem, -0.3495145631067961rem + 3.6245954692556634vw, 4rem) 0;
  text-align: center;
  width: 100%;
}
@media (min-width: 1066px) {
  .calendar-container {
    display: flex;
    justify-content: space-between;
    margin-bottom: 5rem;
    flex-wrap: wrap;
  }
  .calendar-container > * {
    width: 48%;
  }
 
}

@media (max-width: 1066px) {
.calendar-container>.second-heading {
  margin-bottom: calc(.5 * clamp(5rem, 2.2865412445730824rem + 11.577424023154848vw, 10rem));
}

}
