.implicit-open {
  opacity: 0 !important;
  visibility: hidden !important;
  pointer-events: none !important;
}

.modal-subscribe .ctct-form-embed.form_0 .ctct-form-defaults {
  background-color: transparent !important;
  backdrop-filter: none !important;
  width: 100% !important;
}

.modal-subscribe .ctct-form-header {
  display: none;
}

.modal-subscribe .ctct-form-element {
  border: 1px solid #FF9ED2 !important;
  padding: 16px !important;
  font-size: 24px !important;

  color: #681e46 !important;
  border-radius: 1.3rem !important;
  -webkit-border-radius: 1.3rem !important;
  -moz-border-radius: 1.3rem !important;
  -ms-border-radius: 1.3rem !important;
  -o-border-radius: 1.3rem !important;
  transition-duration: 300ms;
}

.modal-subscribe .ctct-form-element::placeholder {
  color: #FF9ED288 !important;
    font-size: 24px !important;
}

.modal-subscribe .ctct-form-element:focus {
  outline: none !important;
  border-color: #681e46 !important;
}

.modal-subscribe .ctct-form-button {
  width: 50% !important;
  padding: 16px !important;
  margin: 0 auto !important;
  font-size: 20px !important;

  background-color: #FF9ED2BB !important;
  border-radius: 1.3rem !important;
  -webkit-border-radius: 1.3rem !important;
  -moz-border-radius: 1.3rem !important;
  -ms-border-radius: 1.3rem !important;
  -o-border-radius: 1.3rem !important;
}

@media screen and (max-width: 768px) {
  .modal-subscribe .ctct-form-button {
    font-size: 18px !important;
  }
}

.modal-subscribe .ctct-form-button:hover {
  background-color: #FF9ED2 !important;
}

.modal-subscribe .ctct-form-success .ctct-form-header {
  display: block;
}

.modal-subscribe .ctct-form-success h2 {
  color: #FF9ED2 !important;
}