.private-instructions .instruction-content {
  font-family: var(--font-family-1);
  font-size: 0.75rem;
  font-size: var(--font-size-12-24);
  padding: var(--section-padding);
  color: var(--primary-color);
  padding-right: clamp(
    1.0625rem,
    -0.6365291262135921rem + 7.249190938511327vw,
    8.0625rem
  );
  padding-top: 0;
}
/* .private-instructions .instruction-content * {
  padding: 0;
  margin: 0;
} */
.instruction-content > .second-heading {
  margin-top: clamp(2.81rem, 12vw, 5.625rem);
}
.instruction-content p {
  color: var(--primary-color);
}
.private-instructions .instruction-content a {
  font-family: var(--font-family-1);
  font-size: 0.75rem;
  font-size: var(--font-size-12-24);
  text-decoration: none;
  color: #fff;
  background: #812990;
  display: inline-block;
  padding: clamp(0.88rem, 3.73vw, 1.44rem) clamp(1.69rem, 7.2vw, 2.5rem);
  margin-top: clamp(0.688rem, 2.93vw, 3.25rem);
  margin-bottom: clamp(2.38rem, 10.13vw, 3.75rem);
  position: relative;
  z-index: 2;
}
.private-instructions .instruction-content > ul.courses {
  list-style-type: none;
  display: grid;
  grid-template-columns: repeat(3, 1fr) 2fr;
  justify-content: space-between;
  margin-bottom: clamp(
    1.625rem,
    1.2609223300970873rem + 1.5533980582524272vw,
    3.125rem
  );
}
.private-instructions .instruction-content > ul.student-types {
  list-style: none;
}

.firstParagraphPrivateInstructions {
  margin-top: 10px;
}

.privateInstructionsIcons {
  width: 70px;
  height: 70px;
}
.courses > li {
  font-size: large;
}

.fontSize25 {
  font-size: 25px;
}

@media (min-width: 1066px) {
  .private-instructions .instruction-content strong {
    font-weight: 600;
  }
}
@media (min-width: 1466px) {
  .private-instructions .instruction-content > ul.student-types {
    display: grid;
    grid-template-columns: auto auto;
  }
}
