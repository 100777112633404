.searchContainer {
  position: relative;
}
.searchInput {
  border-radius: 20px;
  padding: 5px 15px;
  width: 100%;
  border: 2px solid #dddddd;
  outline: none;
  transition: ease-in-out 0.1s;
  cursor: pointer;
  color: #979797;
  font-weight: 500;
}
.searchInput:hover,
.searchInput:focus,
.searchInput:active {
  border: 2px solid #681e46;
}
.userActions {
  display: flex;
  justify-content: space-around;
  color: #151515;
  font-weight: 500;
  opacity: 0.9;
}
.userActions .icon:hover,
.userActions .icon:focus,
.userActions .icon:active {
  color: #ff008a;
  cursor: pointer;
}
.HeadContainer {
  width: 100% !important;
  height: 100% !important;
  display: flex !important;
  justify-content: center !important;
  align-items: center !important;
  text-align: center !important;
}
.actions .HeadContainer {
  width: 60px !important;
  margin: auto !important;
}
.tableConatiner {
  overflow-x: hidden;
  width: 100%;
}
.studentTables {
  color: #000;
  width: 100%;
}
.TableRow {
  height: 70px;
}
.TableRow td {
  color: #151515;
  font-weight: 500;
  text-transform: capitalize;
  opacity: 0.9;
}
.TableRow:active td:first-child,
.TableRow:hover td:first-child,
.TableRow:focus td:first-child {
  color: #ff008a;
  cursor: pointer;
}
.TableRow th,
.TableRow td {
  height: 100%;
  border: 1px solid #ddd;
  padding: 7px;
  text-align: center;
}
.pagination {
  display: flex;
  justify-content: center;
  text-align: center;
  margin: 15px;
  overflow: hidden;
}
.pagination li {
  margin: 20px;
  padding: 10px;
  font-size: 15px;
  font-weight: 900;
  color: #681e46;
  transition: all 0.4s;
}
.pagination li.selected {
  background-color: #fff;
  border-radius: 50%;
  color: #681e46;
  border: 2px solid #681e46;
  padding: 10px 18px;
}
.previous,
.next {
  display: inline-block;
  text-align: center;
}
.previous a,
.next a {
  color: #fff;
  border-radius: 10px;
  background-color: #681e46;
  color: #fff;
  border: 3px solid #681e46;
  padding: 10px;
}
/* .previous:hover ,
.next:hover
{
    background-color: #fff;
    border: 2px solid #681E46;
} */
.previous:hover a,
.next:hover a {
  color: #681e46;
  border: 3px solid #681e46;
  background-color: #fff;
}
/* add student css edit*/
.modal-title {
  width: 75%;
}
.headerContainer {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.headerContainer h3 {
  color: #151515;
  margin: 0;
  font-size: 26px;
  font-weight: bold;
  text-transform: capitalize;
}
.headerContainer p {
  color: #dddddd;
  margin: 0;
  font-size: 18px;
  font-weight: 600;
  opacity: 1;
}
.imageContainer .userImage {
  position: absolute;
  height: 119px;
  opacity: 1;
  top: 46px;
  left: 36px;
  border-radius: 50%;
  object-fit: cover;
}
.buttonContainer {
  padding: 0.5rem 1rem;
}
.buttonContainer button {
  padding: 10px;
  font-size: 16px;
  font-weight: 500;
  background-color: #681e46;
  color: #fff;
  border-radius: 30px;
  border: 3px solid #681e46;
  transition: all 0.3s;
  outline: none;
  box-shadow: none;
}

.buttonContainerAlign {
  padding: 0.5rem 1rem;
}
.buttonContainer button:hover {
  background-color: #fff;
  color: #681e46;
  transition: all 0.3s;
  border: 3px solid #681e46;
  outline: none;
  box-shadow: none;
}
.InputGroup {
  display: flex;
  width: 100%;
}
.InputGroup label,
.SoloInputGroup label {
  display: block;
  color: #151515;
  margin: 5px 15px;
  font-size: 15px;
  font-weight: 600;
  text-transform: capitalize;
}
.homeAddress .InputField {
  width: 100%;
}
.SoloInputGroup .inputContainer .InputField {
  width: 100%;
}
.lastInputGroup .inputContainer,
.updateInputGroup .inputContainer {
  width: 50%;
}
.lastInputGroup .inputContainer .InputField,
.updateInputGroup .inputContainer .InputField {
  width: 100%;
}
.lastInputGroup .inputContainer:first-child,
.updateInputGroup .inputContainer:first-child {
  margin-right: 10px;
}
.inputContainer .InputField {
  border-radius: 10px;
  padding: 10px;
  color: #505050;
  font-weight: 500;
  background-color: #681e460d;
  border: 1px solid #681e460d;
  outline: none;
  width: 225px;
  margin: 10px;
}
.InputField:hover,
.InputField:focus {
  background-color: #681e460d;
  border: 1px solid #681e460d;
  outline: none;
}
.errorMsg {
  text-align: left !important;
  color: #681e46;
  font-weight: 600;
  margin-left: 20px;
}
.form-group {
  width: 95% !important;
}
.submitButton {
  transition: all 0.4s;
}
.submitButton {
  color: #fff;
  background-color: #681e46;
  border: 3px solid #681e46;
  border-radius: 30px;
  padding: 10px;
  font-weight: 700;
}
.submitButton:hover {
  background-color: #fff;
  border: 3px solid #681e46;
}
.submitButton:hover .buttonSpan,
.submitButton:hover .buttonicon {
  color: #681e46;
}
.lessonContainer {
  display: flex;
  padding: 0px 20px;
}
.userInfoContainer {
  display: flex;
  flex-direction: column;
}
.userDetails {
  width: 120px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.userDetails h3 {
  color: #151515;
  margin: 0;
  font-size: 18px;
  text-align: center;
  margin: 7px 0;
  font-weight: bold;
  text-transform: capitalize;
}
.userDetails p {
  color: #dddddd;
  margin: 0;
  font-size: 18px;
  font-weight: 600;
  opacity: 1;
}
.userInfoContainer .inviteLink,
.showLink,
.submitbutton {
  padding: 10px;
  font-size: 13px;
  font-weight: 500;
  background-color: #681e46;
  color: #fff;
  border-radius: 30px;
  border: 3px solid #681e46;
  transition: all 0.3s;
}
.userInfoContainer .inviteLink:hover,
.showLink:hover,
.submitbutton:hover {
  background-color: #fff;
  color: #681e46;
  transition: all 0.3s;
  border: 3px solid #681e46;
}
.userAddlessonContainer,
.lessonfirstContainer {
  width: 100%;
}
.lessonfirstContainer {
  display: flex;
  justify-content: space-around;
}
.ExtraInfoContainer {
  display: flex;
  padding: 10px 28px;
}
.InfoConatiner {
  width: 30%;
  margin: auto;
}
.textArea {
  color: #858585 !important;
  font-weight: 600;
  width: 100%;
  margin: 10px;
  box-shadow: 10px #eeee;
  border-radius: 10px;
  resize: none;
  height: 100px;
  padding: 10px 10px;
  border: 1px solid #eee;
  box-shadow: 3px 2px 9px 4px #f5f5f5;
  outline: none;
}
.textArea:hover,
.textArea:focus,
.textArea:active {
  border: 1px solid #eee;
  box-shadow: 3px 2px 9px 4px #f5f5f5;
  outline: none;
}
.textArea::-webkit-scrollbar {
  width: 7px;
}
/* Track */
.textArea::-webkit-scrollbar-track {
  background: #f5f5f55e;
}

/* Handle */
.textArea::-webkit-scrollbar-thumb {
  background: #dddddd;
}
.noteLabel {
  margin-left: 20px;
  text-transform: capitalize;
  color: #8e8c8c !important;
  font-weight: 700;
}
.AbsentContainer {
  display: flex;
}
.modal-body {
  padding: 30px 30px 24px 30px !important;
}
.ModalHead {
  color: #151515;
}
.modal-header h3 {
  font-size: 25px !important;
  color: #151515 !important;
}
.tableContainer {
  overflow-x: hidden;
  width: 100%;
}
.StudentAbsentTable {
  color: #151515;
  width: 100%;
  border: none;
  border-collapse: collapse;
}
.StudentAbsentTable .tableHead .TableRow,
.StudentAbsentTable .TableRow th,
.StudentAbsentTable .TableRow td {
  border: 1px solid #ddd;
  border-top: 0;
  border-bottom: 0;
}
.StudentAbsentTable .tableBody .TableRow {
  border: 1px solid #ddd;
}
.StudentAbsentTable .TableRow {
  height: 50px;
}
.TableRow td {
  color: #151515;
  font-weight: 500;
  opacity: 0.9;
}
.TableRow:active td:first-child,
.TableRow:hover td:first-child,
.TableRow:focus td:first-child {
  color: #ff008a;
  cursor: pointer;
}
.StudentAbsentTable .TableRow th,
.StudentAbsentTable .TableRow td {
  height: 100%;
  padding: 5px;
  text-align: center;
}
.AbsentDetails {
  color: #151515;
}
.AbsentDetails h4 {
  font-size: 16px;
}
.userModal .modal-content {
  padding: 0 !important;
}
.userHeader .modal-title {
  width: 100%;
  margin-top: 20px;
}
.userData {
  width: 25%;
}
.userData .userDetails h3 {
  font-size: 18px;
  margin: 10px 0 0 0 !important;
  text-align: center;
}

.HoursInpu,
.MinInpu {
  width: 45px;
  height: 40px;
  border-radius: 5px;
  margin-right: 5px;
  background-color: #681e460d !important;
  outline: none;
  color: black !important;
  border: 1px solid #f5f5f55e !important;
  border-radius: 7px !important;
  text-align: center;
}
.UserHeader {
  background-color: #681e46;
  position: relative;
  height: 270px !important;
  margin-bottom: 85px;
}
.SvgWave {
  position: absolute;
  top: 100%;
  width: 100%;
  left: 0;
}
.UsableContainer {
  display: flex;
  justify-content: space-between;
}
.userQRCode {
  width: 150px;
  height: 150px;
}
.UsableSinglePart {
  width: 98%;
  margin: 2%;
  overflow: hidden;
}
.UsableSinglePart p {
  color: #681e46;
  font-size: 18px;
  font-weight: 600;
  margin-bottom: 10px;
  margin-left: 10px;
  text-transform: capitalize;
}
.UsableSinglePart div {
  display: flex;
  align-items: center;
  justify-content: left;
  border-radius: 10px;
  padding: 5px 8px;
  background-color: #00000008;
  border: 1px solid #ddd;
}
.UsableSinglePart div p {
  color: #151515;
  margin-bottom: 0;
}
.CenterLine {
  color: #625656;
  width: 80%;
  margin: auto;
  height: 3px !important;
}
.UserlessonsData h3 {
  color: #151515;
  font-size: 24px;
  font-weight: 500;
}
.UserlessonsData .btnsContainer .btn:first-of-type {
  margin: 0;
}
.UserlessonsData .btnsContainer .btn {
  margin-left: 10px;
}
.LessonsAbsence {
  color: #151515;
}
.LessonsAbsence h3 {
  color: #151515;
  font-size: 24px;
  font-weight: 500;
}
.abscenceTypes {
  width: 70%;
  margin: auto;
  display: flex;
  justify-content: center;
  align-items: center;
}
.Types {
  padding: 20px;
  border: 4px solid #ddd;
  margin: 5px;
  border-top: 0;
  border-bottom: 0;
}
.Types span {
  color: #151515;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.3s;
}
.Types span:hover {
  color: #681e46;
}
.lessonsContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.Lessons {
  width: 100%;
  padding: 0px 25px;
  display: flex;
}
.Lessons p {
  color: #151515;
  font-size: 17px !important;
  font-weight: 600;
  text-transform: lowercase;
}
.Lessons div:first-of-type {
  margin-right: 20px;
}
.CategContainer {
  position: relative;
  text-align: center;
  display: flex;
  flex-direction: row;
  justify-content: left;
}
.hidePart {
  display: none;
}
.showPart {
  display: block;
}
.CategContainer p:first-of-type {
  color: #681e46;
  margin-right: 5px;
  text-transform: capitalize;
}
.btnsContainer {
  display: flex;
}
.btnsContainer .btn {
  padding: 5px;
  font-size: 18px;
  font-weight: 600;
  background-color: transparent;
  border: none;
  text-transform: capitalize;
  outline: none;
  box-shadow: none;
  color: #151515;
}
.btnsContainer .btn:hover,
.btnsContainer .btn:focus,
.btnsContainer .btn:active {
  box-shadow: none;
  outline: none;
  color: #910050;
}
.btnsContainer .unactive {
  color: #151515;
}
.btnsContainer .active {
  color: #910050;
}

.name-cell {
  text-align: left !important;
  padding: 24px !important;
}
