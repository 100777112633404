.profile-page {
  --amplify-ratio: 1.5;
  --clamp-29-58: calc(
    var(--amplify-ratio) *
      clamp(1.8125rem, 1.3725728155339807rem + 1.8770226537216828vw, 3.625rem)
  );
  --clamp-19-38: calc(
    var(--amplify-ratio) *
      clamp(1.1875rem, 0.8992718446601942rem + 1.2297734627831716vw, 2.375rem)
  );
  --clamp-13-26: calc(
    var(--amplify-ratio) *
      clamp(0.8125rem, 0.6152912621359223rem + 0.8414239482200648vw, 1.625rem)
  );
  --clamp-9-18: calc(
    var(--amplify-ratio) *
      clamp(0.5625rem, 0.42597087378640774rem + 0.5825242718446602vw, 1.125rem)
  );
  --clamp-7-14: calc(
    var(--amplify-ratio) *
      clamp(0.4375rem, 0.3313106796116505rem + 0.45307443365695793vw, 0.875rem)
  );
  --clamp-8-16: calc(
    var(--amplify-ratio) *
      clamp(0.5rem, 0.3786407766990291rem + 0.517799352750809vw, 1rem)
  );
  --clamp-75-150: clamp(
    4.6875rem,
    3.549757281553398rem + 4.854368932038835vw,
    9.375rem
  );
  --clamp-250-500: calc(
    1.5 *
      clamp(15.625rem, 11.83252427184466rem + 16.181229773462782vw, 31.25rem)
  );
  --clamp-0-24: calc(
    1 * clamp(0rem, -0.3640776699029126rem + 1.5533980582524272vw, 1.5rem)
  );
  --light-gray-color-1: #979797;
}

.profile-page .profile-personal-details {
  width: 100%;
  padding-top: 3rem;
}

.profile-page .profile-personal-details .profile-image {
  --white-color: #ffffff;
  margin: auto;
  width: fit-content;
  background-color: var(--light-gray-color-1);
  display: block;
  width: 60%;
  max-width: 10rem;
  aspect-ratio: 1;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.profile-page .profile-personal-details .profile-image img {
  aspect-ratio: 1;
  width: 100%;
  border-radius: 50%;
}

.profile-page .profile-personal-details h2 {
  margin-top: 0.75rem;
  text-align: center;
  font-family: var(--font-family-1);
  font-size: 1.5rem;
  font-weight: 600;
}

.profile-page .profile-personal-details h3 {
  margin-top: 0.5rem;
  text-align: center;
  font-family: var(--font-family-1);
  font-size: 1rem;
  color: #3a3a3a;
  font-weight: 500;
}

.profile-page .profile-personal-details .profile-background-image {
  display: none;
}

@media (min-width: 1400px) {
  .profile-page {
    /* padding: var(--clamp-29-58) var(--clamp-19-38); */

    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.75rem;
    height: var(--clamp-250-500);
    padding: 20px 40px;
    /* aspect-ratio: 10/5; */
  }

  .profile-page .profile-personal-details {
    aspect-ratio: 244/380;
    height: 76%;
    width: auto;
    padding: 0;
    margin: 0;

    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: relative;
  }

  .profile-page .profile-personal-details .profile-image {
    background-color: #ffffff;
    width: 50%;
    z-index: 2;
    width: 50%;
    margin: 0;
  }

  .profile-page .profile-personal-details .profile-image img {
    width: 92%;
  }

  .profile-page .profile-personal-details h2 {
    font-size: 1rem;
    font-size: var(--clamp-13-26);
    color: #ffffff;

    margin-top: var(--clamp-13-26);

    text-transform: capitalize;
    z-index: 2;
  }

  .profile-page .profile-personal-details h3 {
    font-size: 0.5rem;
    font-size: var(--clamp-9-18);
    color: var(--light-gray-color-1);

    text-transform: capitalize;
    z-index: 2;
  }

  .profile-page.student .profile-personal-details h3 {
    --light-gray-color-1: #3a3a3a;
  }

  .profile-page .profile-personal-details .profile-background-image {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    width: auto;
    z-index: 1;
  }

  .profile-page .profile-personal-details .profile-background-image img {
    height: 100%;
    width: auto;
    aspect-ratio: 244/380;
  }
}

.buttons-wrapper {
  position: absolute;
  top: 90%;
  width: 100%;
  justify-content: center;
}

@media screen and (max-width: 1400px) {
  .buttons-wrapper {
    top: 105%;
  }
}

.resend-email {
  display: flex;
  flex-direction: column;
  align-items: center;
}