.contact-us > .form-header {
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: center;
  gap: clamp(0.4375rem, 0.024999999999999967rem + 2.0625vw, 2.5rem);
  padding: 0;
  margin: 0;
  margin-top: clamp(1.4375rem, 1.0375rem + 2vw, 3.4375rem);
  margin-bottom: clamp(2.3125rem, 1.9625rem + 1.7500000000000002vw, 4.0625rem);
}
.contact-us > .form-header > :nth-child(odd) {
  font-family: var(--font-family-2);
  font-size: 0.75rem;
  font-size: clamp(0.75rem, 0.30000000000000004rem + 2.25vw, 3rem);
  color: var(--primary-color);
  width: fit-content;
  margin: 0;
  padding: 0;
  align-self: start;
  justify-self: start;
}
.contact-us > .form-header > :nth-child(even) {
  font-family: var(--font-family-1);
  color: var(--primary-color);
  font-size: 0.625rem;

  font-size: clamp(0.625rem, 0.35rem + 1.375vw, 2rem);
  width: fit-content;
  margin: 0;
  padding: 0;
  text-decoration: none;
}
.contact-us > .contact-form {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  row-gap: clamp(0.5rem, 0.22499999999999998rem + 1.375vw, 1.875rem);
}
.contact-us > .contact-form * {
  font-family: var(--font-family-1);
  color: var(--primary-color);
  font-size: 0.75rem;
  font-size: clamp(0.75rem, 0.5rem + 1.25vw, 2rem);
  width: 100%;
  background: rgba(240, 181, 198, 0.6);
  box-shadow: inset -1px 6px 12px rgba(0, 0, 0, 0.16);
  border: none;
  padding: clamp(0.5rem, 0.22499999999999998rem + 1.375vw, 1.875rem);
}
.contact-us > .contact-form > *::placeholder {
  color: var(--primary-color);
  font-family: var(--font-family-1);
  color: var(--primary-color);
  font-size: 0.75rem;
  font-size: clamp(0.75rem, 0.5rem + 1.25vw, 2rem);
  width: 100%;
}
@media (min-width: 1066px) {
  :is(.contact-us > .contact-form) > :is(:nth-child(1), :nth-child(2)) {
    width: 48%;
  }
}
.contact-us > .contact-form > textarea {
  height: clamp(8.75rem, 6.125rem + 13.125vw, 21.875rem);
}
.contact-us > .contact-form > input[type="submit"] {
  background: rgba(129, 41, 144, 1);
  color: #fff;
  width: fit-content;
  margin-left: auto;
  padding: clamp(
      0.875rem,
      0.6474514563106796rem + 0.9708737864077671vw,
      1.8125rem
    )
    clamp(2rem, 0.5740291262135924rem + 6.084142394822006vw, 7.875rem);
}
