.about-us {
  position: relative;
}
.about-us .about-us-article {
  padding: var(--section-padding);
  padding-right: clamp(3.63rem, 15.74vw, 17.38rem);
}
.about-us > .about-us-article > p {
  color: #000000;
  font-size: 0.75rem;
  font-size: var(--font-size-12-24);
  font-family: var(--font-family-1);
  max-width: 90ch;
}

@media (min-width: 1066px) {
  .about-us {
    margin: 8.75rem 0;
  }
  .about-us .about-us-article p {
    max-width: 90ch;
  }
}

.guest-map-container {
  display: flex;
  margin-top: 32px;
  width: 150px;
  height: 32px;
}

.guest-map-container > ul {
  width: 100%;
  height: 100%;
}

@media screen and (min-width: 769px) {
  .guest-map-container {
    display: none;
  }
}
