.footer-container .subscribe * {
    margin: 0;
    padding: 0;
}

.subscribe .ctct-form-embed.form_0 .ctct-form-defaults{
background-color: rgba(255, 255, 255, 0.15);
backdrop-filter: blur(30px);
border-radius: 0;
width: clamp(9.4375rem,
    -1.211771844660193rem + 45.43689320388349vw,
    53.3125rem);
    padding:  clamp(0.25rem, -0.1444174757281554rem + 1.6828478964401297vw, 1.875rem) clamp(0.4375rem, -0.03276699029126218rem + 2.0064724919093853vw, 2.375rem)  clamp(0.5rem, 0.24211165048543692rem + 1.1003236245954693vw, 1.5625rem);
}
.subscribe .ctct-form-embed.form_0 .ctct-form-defaults .ctct-form-header {
    text-align: center;
    color: #fff;
    font-family: var(--font-family-2);
    font-size: var(--font-size-10);
    font-size: var(--font-size-10-36);
    font-weight: bold;
    margin: 0;
    margin-bottom:  clamp(0.3125rem, -0.2184466019417476rem + 2.26537216828479vw, 2.5rem);
    padding: 0 1rem;

}
.subscribe div.ctct-form-embed form.ctct-form-custom label.ctct-form-label {
    display: none;
}
.subscribe #email_address_field_0{
    margin: 0;
}
/* input */
.subscribe div.ctct-form-embed form.ctct-form-custom input.ctct-form-element {
    color: #fff;
    background-color: rgba(255, 255, 255, 0.2);
    border-radius: 0;
    /* backdrop-filter: blur(30px); */
    
    border: none;
    padding: clamp(0.3125rem, -0.12742718446601942rem + 1.8770226537216828vw, 2.125rem);
    font-size: 0.5rem;
    font-size: clamp(0.5rem, 0.19660194174757278rem + 1.2944983818770228vw, 1.75rem);
    outline: none;
    margin: 0;
    margin-bottom: clamp(0.5625rem, 0.09223300970873782rem + 2.0064724919093853vw, 2.5rem);
    height: max-content;
    
}
/* success message */
.subscribe div.ctct-form-embed div.ctct-form-defaults div.ctct-form-success {
    display: flex;
    align-items: center;
    justify-content: center;
}
.subscribe div.ctct-form-embed div.ctct-form-defaults p.ctct-form-text {
    display: none;
}
.subscribe .ctct-form-embed.form_0 .ctct-form-defaults div.ctct-form-success .ctct-form-header {
margin: 0;
}
.subscribe div.ctct-form-embed form.ctct-form-custom input.ctct-form-element::placeholder {
    color: #fff;
    text-transform: uppercase;
    font-weight: 400;
    font-size: 0.5rem;
    font-size: clamp(0.5rem, 0.19660194174757278rem + 1.2944983818770228vw, 1.75rem);
}



.subscribe #gdpr_text {
    display: none;
}
/* button */
.subscribe .ctct-form-embed.form_0 .ctct-form-custom .ctct-form-button {
    color: #151515;
    background-color: #FF9ED2;
    box-shadow: 0px 0px 5px #FF9ED2;
    font-family: var(--font-family-1);
    font-size: 0.5rem;
    font-size: clamp(0.5rem, 0.19660194174757278rem + 1.2944983818770228vw, 1.75rem);
    padding: clamp(0.3125rem, -0.12742718446601942rem + 1.8770226537216828vw, 2.125rem) 0;
    border: none;
    border-radius: 0;
    text-transform: uppercase;
    margin: 0;

}
.subscribe .ctct-form-embed.form_0 .ctct-form-custom .ctct-form-button:hover {
    border: none;
    background-color: #FF9ED2;
    outline: none;
}
div.ctct-form-embed div.ctct-form-defaults p.ctct-form-footer {
display: none;
}
/* captcha */
.subscribe #ctct_recaptcha_0 {
    visibility: hidden;
    height: 0;
}


