.footer-container {
  padding: clamp(
      0.5rem,
      0.18143203883495146rem + 1.3592233009708738vw,
      1.8125rem
    )
    clamp(2.0625rem, 0.48483009708737845rem + 6.731391585760519vw, 8.5625rem)
    clamp(0.0175rem, -1.1918446601941748rem + 5.159870550161813vw, 5rem);
  background: transparent
    linear-gradient(90deg, #d75e6f 0%, #681e46 67%, #681e46 100%) 0% 0%
    no-repeat padding-box;
  color: white;
  position: relative;
  padding-top: 0;
  overflow-x: hidden;
}
.footer-container * {
  padding: 0;
  margin: 0;
  line-height: unset;
  text-decoration: none;
  color: #fff;
  font-family:var(--font-family-1);
  font-weight: 400;

}

.footer-container > .footer-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: solid rgba(255, 158, 210, 0.8) 2px;
  max-width: 1616px;
  margin: 0 auto;
}

:is(.footer-container > .footer-header) > article {
align-self: baseline;

}
:is(.footer-container > .footer-header)  :is(article, p, pre) {
  font-size: 0.5rem;
  font-size: var(--clamp-8-36);
}
.footer-container > .footer-header > figure {
  width: clamp(8.25rem, 3.5776699029126213rem + 19.93527508090615vw, 27.5rem);
  margin: 0;
}
.footer-container > .footer-header > figure > img {
  width: 100%;
  height: auto;
}


.footer-container > .footer-footer {
  display: flex;
  gap: clamp(0.5rem, -0.3191747572815534rem + 3.4951456310679614vw, 3.875rem);
  justify-content: center;
  padding-top: clamp(
    0.9375rem,
    0.7099514563106796rem + 0.9708737864077671vw,
    1.875rem
  );
  align-items: center;
}

.footer-container > .footer-footer > .school-hours {
  position: relative;
  padding: clamp(
      0.3125rem,
      -0.006067961165048541rem + 1.3592233009708738vw,
      1.625rem
    )
    clamp(0.5rem, 0.0449029126213592rem + 1.9417475728155342vw, 2.375rem)
    clamp(0.4375rem, -0.07827669902912615rem + 2.2006472491909386vw, 2.5625rem);
  width: fit-content;
  color: #fff;
  position: relative;
}

.footer-container > .footer-footer > .school-hours::before {
  position: absolute;
  z-index: 0;
  inset: 0;
  background-color: rgba(255, 255, 255, 0.15);
  content: "";
  backdrop-filter: blur(30px);
}
.footer-container > .footer-footer > .school-hours > h2 {
  position: relative;
  font-weight: 600;
  font-size: 0.625rem;
  font-size: clamp(
    0.625rem,
    0.35194174757281554rem + 1.1650485436893203vw,
    1.75rem
  );
  letter-spacing: normal;
  font-family: "Times New Roman";
}
.footer-container > .footer-footer > .school-hours > p {
  position: relative;
  font-size: 0.375rem;
  font-size: clamp(
    0.375rem,
    0.10194174757281554rem + 1.1650485436893203vw,
    1.5rem
  );
  color: #fff;
}
.footer-container > .footer-footer > .school-hours > p:nth-of-type(1) {
  margin-bottom: clamp(
    0.25rem,
    -0.007888349514563076rem + 1.1003236245954693vw,
    1.3125rem
  );
}
.footer-container > .footer-footer > .social-icons {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-self: stretch;
  width: clamp(
    0.75rem,
    0.18871359223300976rem + 2.394822006472492vw,
    3.0625rem
  );
}
.footer-container > .footer-footer > .social-icons > a {
  width: 100%;
  height: auto;
}
.footer-container > .footer-footer > .social-icons > a > img {
  width: 100%;
  height: auto;
}
