.news-page>.news-element {
    margin: 3rem auto;
    width: 90%;
    max-width: 1500px;
}
.news-page>.news-element> .news-element-heading {
    color: #151515;
    width: fit-content;
    font-family: var(--font-family-2);
    font-weight: bold;
    font-size: var(--font-size-28);
    font-size: var(--font-size-28-56);
    margin: 0;
    padding: 0;
    padding-bottom: clamp(0.5rem,
            0.31796116504854366rem + 0.7766990291262136vw,
            1.25rem);
    margin-bottom: clamp(0.5rem,
            0.4696601941747573rem + 0.12944983818770225vw,
            0.625rem);
    text-align: left;
}

.news-page>.news-element>.attachment-container {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
    overflow: hidden;
    margin: auto;
    margin-bottom: clamp(1rem,
            0.620752427184466rem + 1.6181229773462782vw,
            2.5625rem);
    max-width: 1500px;

}

.news-page>.news-element>.attachment-container .attachment {
    max-width: 100%;
    width: 100%;
    height: auto;
    display: inline;
    vertical-align: middle;
    margin: 0;
    max-height: 100%;
    padding: 0;
    /* aspect-ratio: 1.5; */

}

.news-page>.news-element>.attachment-container .attachment .attachment-page> .react-pdf__Page__canvas {
    width: 100% !important;
    height: auto !important;
    border: none;
    border-radius: 0;
}



.news-page>.news-element>.summary {
    color: #151515;
    font-family: var(--font-family-1);
    font-size: var(--font-size-22);
    font-size: var(--clamp-8-36)
}
