.Container {
  box-shadow: 0px 6px 15px #00000008;
  border-radius: 10px;
  padding: 10px;
  color: #151515;
}
@media (max-width: 1200px) {
  .Container {
    height: 85vh;
  }
}
.ToggleBtnsContainer {
  display: flex;
  /* justify-content: center; */
  justify-content: left;
  align-items: center;
}
.ItemsContainer {
}
.ToggleBtnsContainer,
.ItemsContainer {
  padding: 0px 20px;
}
.ToggleBtnsContainer button {
  background-color: transparent;
  border: none;
  text-transform: capitalize;
  outline: none;
  box-shadow: none;
  padding: 25px;

  /* color: #bbb; */
  color: #ddd;
  /* font-size: 20px; */
  font-size: 1.3rem;
  /* font-weight: 600; */
  font-weight: 700;
}
@media (max-width: 576px) {
  .ToggleBtnsContainer button {
    font-size: 0.9rem;
  }
}
.ToggleBtnsContainer .button.active {
  color: #151515;
  position: relative;
}
.ToggleBtnsContainer .button.active::before {
  content: "";
  width: 50%;
  height: 3px;
  position: absolute;
  left: 2px;
  bottom: 20px;
  background-color: #681e46;
  border-radius: 10px;
}
.ToggleBtnsContainer .button:first-of-type {
  padding-left: 0;
}
.ToggleBtnsContainer .button:hover {
  box-shadow: none;
  outline: none;
  color: #151515;
}
.MainContainer {
  display: flex;
  padding: 15px 0;
  transition: all 0.4s ease-in-out;
  cursor: pointer;
  border-radius: 10px;
  padding: 10px;
  justify-content: space-between;
}
.MainContainer:hover {
  background-color: #dddddd;
}
.CheckBox {
  margin-right: 20px;
}
.Description {
  max-width: 450px;
  display: flex;
  align-items: center;
  width: 65%;
}
.Description .simpleData span {
  color: #151515;
  font-size: 13px;
  text-transform: capitalize;
  font-weight: 400;
}
.ImageContainer {
  width: 65px;
  height: 65px;
  margin: 0px 10px;
}
.ImageContainer img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
}
.fileContainer {
  width: 100px;
  height: 100px;
  margin: 0 10px 0 10px;
}
.small .react-pdf__Page__canvas {
  width: 70px !important;
  height: 70px !important;
  object-fit: cover;
  /* box-shadow: 0px 6px 7px 0px #151515; */
  border-radius: 10px;
}
.react-pdf__Page__textContent {
  display: none !important;
}
.FilesTypeContainer {
  max-width: 650px;
  max-height: 350px;
  overflow: hidden;
}
.ImageNewsFeedContainer {
  max-width: 75%;
}
.videoFeedConatiner {
  max-width: 85%;
}
.ImageNewsFeedContainer img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
}
.PDFContainer {
  width: 65%;
  height: 65%;
}
.PDFContainer .small .react-pdf__Page__canvas {
  width: 100% !important;
  height: 100% !important;
}
.videoConatiner {
  max-width: 80px;
  max-height: 80px;
}
.videoConatiner .video {
  border-radius: 10%;
}
.videoFeedConatiner video {
  border-radius: 2%;
}
.Actions {
  position: relative;
}
.menuItems {
  box-shadow: 0px 6px 15px #00000008;
  position: absolute;
  z-index: 999999;
  right: 0;
  top: 27px;
  background-color: #fff;
  border-radius: 5px;
  display: block;
}
.menuItems.PinnItem {
  top: 66px;
}
.menuItems.MainTopToggle {
  min-width: 130px;
  left: 0;
  top: 30px;
}
.menuItems.NewsFeedItem {
  top: 70px;
  right: 75px;
}
.menuItems .HorzintalLine {
  width: 90%;
  margin: auto;
  color: #777d;
  background-color: #777d;
  font-weight: 600;
  height: 2px;
}
.menuItems .action {
  color: #000;
  padding: 5px;
  font-weight: 700;
  opacity: 0.7;
}
.menuItems .action.pin {
  color: #000;
  opacity: 0.7;
}
.menuItems .action p {
  margin: 0;
  font-size: 16px;
  font-weight: 600;
  transition: all 0.3s ease-in;
  /* color: #dddddd; */
  padding: 10px 5px;
  text-align: left;
  border-radius: 5px;
  cursor: pointer;
}
.menuItems .action:hover {
  color: #681e46;
  opacity: 1;
}
.menuItemsHide {
  opacity: 0;
  visibility: hidden;
}
.menuItemsShow {
  opacity: 1;
  visibility: visible;
}
.Description p {
  font-size: 15px;
  font-weight: 500;
  text-transform: capitalize;
  color: #151515;
  letter-spacing: 0.3px;
  line-height: 1.5;
  margin-bottom: 0;
  width: 90%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.react-calendar {
  width: 100% !important;
  background-color: #681e460d !important;
  padding: 10px;
  /* padding: 4vw; */

  border: 1px solid #dddddd !important;
  border-radius: 5px;
}
.react-calendar abbr[data-bs-original-title],
abbr[title] {
  color: #151515;
  text-decoration: none !important;
}
.react-calendar__tile--now {
  background: #dddddd !important;
  border-radius: 5px;
}
.react-calendar__tile--active {
  background: #681e46 !important;
  border-radius: 5px;
}
.react-calendar__month-view__days__day--weekend {
  color: #681e46 !important;
}
.react-calendar__tile--active {
  color: #fff !important;
}
.ViewDate h4,
.ViewTime h4,
.ViewWeather h4 {
  font-size: 18px;
  margin-bottom: 0;
  text-transform: lowercase;
}
.tableContainer {
  overflow-x: hidden;
  width: 100%;
}
.studentsTable {
  color: #000;
  width: 100%;
}
.TableRow {
  height: 70px;
}
.TableRow td {
  color: #151515;
  font-weight: 500;
  opacity: 0.9;
}
.TableRow:active td:first-child,
.TableRow:hover td:first-child,
.TableRow:focus td:first-child {
  color: #ff008a;
  cursor: pointer;
}
.TableRow th,
.TableRow td {
  height: 100%;
  border: 1px solid #ddd;
  padding: 7px;
  text-align: center;
}
.userActions {
  display: flex;
  justify-content: space-around;
  color: #151515;
  font-weight: 500;
  opacity: 0.9;
}
.userActions .icon:hover,
.userActions .icon:focus,
.userActions .icon:active {
  color: #ff008a;
  cursor: pointer;
}
#zmmtg-root {
  display: none;
  z-index: 9999;
}
.controls {
  text-align: center;
}
.userContainer {
  margin-left: 10px;
}

.userContainer h3 {
  color: #151515;
  font-size: 19px;
  margin-bottom: 0;
  line-height: none;
}

.userleesonInfo {
  margin-left: 0.5rem;
}

.modal-body {
  padding: 0px 30px 24px 30px !important;
}
.modal-header {
  padding: 1rem 4rem !important;
}
.modal-title {
  width: 100%;
}
.UsableContainer {
  display: flex;
  justify-content: space-between;
}
.userQRCode {
  width: 150px;
  height: 150px;
}
.leesonsDataContainer .UsableSinglePart {
  width: 98%;
  margin: 1%;
  overflow: hidden;
}

.UsableSinglePart p {
  color: #681e46;
  font-size: 17px;
  font-weight: 600;
  margin-bottom: 10px;
  margin-left: 10px;
  text-transform: capitalize;
}
.UsableSinglePart .DataInfo {
  color: #151515;
}
.UsableSinglePart div {
  display: flex;
  align-items: center;
  justify-content: left;
  border-radius: 10px;
  padding: 5px 8px;
  background-color: #00000008;
  border: 1px solid #ddd;
}
.UsableSinglePart div p {
  color: #151515;
  margin-bottom: 0;
}
.NoteContainer {
  display: block !important;
  height: 200px;
  max-height: 200px;
  overflow-y: auto;
}
.ItemData {
  margin-top: 15px;
  margin-bottom: 15px;
}
.ItemData h4 {
  color: #151515;
  font-weight: 700;
}
.ItemData p {
  font-size: 18px;
  color: #000;
  font-weight: 600;
  margin-bottom: 0;
}
.ParagraphData {
  display: flex;
  align-items: center;
  justify-content: left;
  border-radius: 10px;
  padding: 5px 8px;
  background-color: #00000008;
  border: 1px solid #ddd;
  margin-bottom: 20px;
}
