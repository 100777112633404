/* fixing up the height and width problems */
html, body {
  height: max-content;
  overflow: unset;
  max-width: 100vw;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
line-height: normal;
}
@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@400;500;600&display=swap");

html {
/* clamp functions */
    --clamp-16-32: clamp(1rem, 0.7572815533980582rem + 1.035598705501618vw, 2rem);
    --clamp-8-36: clamp(0.5rem, 0.07524271844660196rem + 1.8122977346278317vw, 2.25rem);
    

  font-size: 16px;
  /* css variables */
  /* media queries variables  */
    --desktop-screen-width: 1250px;
  /* font families */
  --font-family-1: "Raleway", sans-serif;
  --font-family-2: "Times New Roman";
  /* font-sizes */
  --font-size-6: 0.375rem;
  --font-size-6-21: clamp(0.375rem,
    0.1474514563106796rem + 0.9708737864077671vw,
    1.3125rem);

  --font-size-8: 0.5rem;
  --font-size-8-12: clamp(0.5rem,
    0.4393203883495146rem + 0.2588996763754045vw,
    0.75rem);
    --font-size-8-48: clamp(0.5rem,
      -0.10679611650485443rem + 2.5889967637540456vw,
      3rem);
      
  --font-size-10: 0.625rem;
  --font-size-10-36: clamp(0.625rem, 0.2305825242718446rem + 1.6828478964401297vw, 2.25rem);

  --font-size-12: .75rem;
  --font-size-12-24: clamp(0.75rem, 0.5679611650485437rem + 0.7766990291262136vw, 1.5rem);
  --font-size-12-32: clamp(0.75rem, 0.5rem + 1.25vw, 2rem);
  --font-size-12-36: clamp(0.75rem, 0.3859223300970874rem + 1.5533980582524272vw, 2.25rem);

  --font-size-14: 0.875rem;
  --font-size-14-20: clamp(0.875rem,
    0.7839805825242718rem + 0.3883495145631068vw,
    1.25rem);

    --font-size-21: 1.3125rem;
    --font-size-21-32: clamp(1.3125rem,
    1.145631067961165rem + 0.7119741100323626vw,
    2rem);
      --font-size-21-56: clamp(1.3125rem, 0.875rem + 2.1875vw, 3.5rem);

    --font-size-22: 1.375rem;
    --font-size-22-40: clamp(1.375rem, 1.1019417475728155rem + 1.1650485436893203vw, 2.5rem);

    --font-size-28: 1.75rem;
    --font-size-28-56: clamp(1.75rem, 1.325242718446602rem + 1.8122977346278317vw, 3.5rem);

      --font-size-31: 1.9375rem;
      --font-size-31-52: clamp(1.9375rem, 1.6189320388349515rem + 1.3592233009708738vw, 3.25rem);

      --font-size-34: 2.125rem;
      --font-size-34-84: clamp(2.125rem, 1.366504854368932rem + 3.2362459546925564vw, 5.25rem);
      
  /* font-shortcuts */ 
  --font-paragraph-bold-small: 500 clamp(0.5rem, 2.14vw, 1.75rem) /
      clamp(0.63rem, 2.67vw, 2.06rem) "Raleway",
    sans-serif;
 
  /* padding variables */
  --section-padding: 2rem clamp(2rem, 8.53vw, 8.81rem);

  --primary-color: #151515;
  --background-primary-color: #681e46;
  --background-secondary-color: #812990;

  /* misc */
  --home-nav-height: clamp(2rem, 1.029126213592233rem + 4.142394822006472vw, 6rem);
  --max-width-1: 2066px;
}
.home-page {

  background: transparent
    linear-gradient(
      180deg,
      #ffffff 0%,
      #de7a88 13%,
      #ffddf0 44%,
      #f7edea 67%,
      #f6f0ee 70%,
      #f6f0ee 87%,
      #ff9ed2 100%
    )
    0% 0% no-repeat padding-box;
  position: relative;
  font-family: "Raleway", sans-serif;
  line-height: unset;
}
.home-page * {
  line-height: unset;
  margin:0;
  padding: 0;
  box-sizing: border-box;
}
/* container */
.home-page > .home-page-container {
  margin: 0;
  width: 100%;
  overflow-x: hidden;
  margin: 0;
  margin-top: calc(-1*var(--home-nav-height));
  z-index: 2;
}
/* common class names */
.second-heading {
  font-size: var(--font-size-31);
  font-size: var(--font-size-31-52);
  font-family: var(--font-family-2);
  font-weight: bold;
  color: #812990;
  margin: 1.56rem 0;
}
.page-heading {
  font-size: var(--font-size-34);
  font-size: var(--font-size-34-84);
  font-family: var(--font-family-2);
  font-weight: bold;
  color: #812990;
  margin: clamp(1.875rem, 0.9648058252427184rem + 3.8834951456310685vw, 5.625rem) 0;
  
}
.home-page figure {
  /* margin: 0; */
}
.home-page p {
  margin: 0;
}
.home-page a {
  text-decoration: none;
}

.home-page a:hover {
  text-decoration: none;
  color: #0a58ca;
  /* color: inherit; */
}

.home-page .question-modal-container {
  position: absolute;
  right: 0;
  top: 110vh;
  width: fit-content;
  bottom: 0;
}

.home-page .questions-modal {
  color: #000;
  position: sticky;
  top: 80vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: #fff;
  border-radius: 1rem 0 0 1rem;
  letter-spacing: 0.16px;
  font: var(--font-paragraph-bold-small);
  width: clamp(3.625rem, 0.287621359223301rem + 14.239482200647249vw, 17.375rem);
  height: clamp(1.8125rem, -0.03822815533980606rem + 7.8964401294498385vw, 9.4375rem);
  z-index: 10;
}

.home-page .questions-modal img {
  width: 21%;
  display: block;
}

@media (min-width: 2066px) {
  .home-page > .home-page-container {
    max-width: 2066px;
    margin: 0 auto;
  }
}
