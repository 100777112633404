.profile-modal-container {
    display: none;
}
.profile-modal-container.active {
    
    --light-burble-1: #F27180;
    --clamp-8-16: clamp(0.5rem, 0.3786407766990291rem + 0.517799352750809vw, 1rem);
    --clamp-35-70: clamp(2.1875rem, 1.6565533980582523rem + 2.26537216828479vw, 4.375rem);
    --amplify-ratio: 1;

    position: fixed;
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;

    font-family: var(--font-family-1);
    z-index: 10;

    background-color: rgba(0, 0, 0, .5);

}
.profile-modal-container.active .profile-modal {
    border-radius: calc(var(--amplify-ratio) * var(--font-size-12-24));
    background-color: #fff;
    font-size: calc(var(--amplify-ratio) * var(--clamp-8-16));

}
.profile-modal-container .profile-modal header {
    background-color: var(--background-primary-color);
    display: flex;
    justify-content: space-between;
    padding: calc(var(--amplify-ratio) * var(--font-size-12-24)) calc(var(--amplify-ratio) * var(--clamp-35-70));
    border-radius: calc(var(--amplify-ratio) * var(--font-size-12-24)) calc(var(--amplify-ratio) * var(--font-size-12-24)) 0 0;
}
.profile-modal-container .profile-modal.student header {
    background-color: var(--light-burble-1);
}

.profile-modal header picture {
    vertical-align: middle;
    text-align: center;
}
.profile-modal header picture.close-icon {
    max-height: 100%;
    max-width: 100%;
    width: fit-content;
    height: 100%;

    cursor: pointer;
}
.profile-modal header img {
    height: calc(var(--amplify-ratio) * var(--clamp-16-32));
    width: auto;
}
.profile-modal header h2 {
    --light-gray-1: #F5F4F4CC;
    color: var(--light-gray-1);
    font-weight: 600;
    font-size: calc(var(--amplify-ratio) * var(--font-size-12-24));
}

.profile-modal form  {
    margin: 20px 60px;
}
.profile-modal form label {
    
    margin-bottom: calc(var(--amplify-ratio) * var(--clamp-8-16));
    text-transform: capitalize;
    font-weight: 600;
    
}
.profile-modal form label::after {
    content: "*";
    color: var(--background-primary-color);
    font-size: calc(1.5 * var(--amplify-ratio) * var(--clamp-8-16));
    
}
.profile-modal form .profile-modal-input {
    padding: calc(var(--amplify-ratio) * var(--clamp-8-16)) calc( var(--amplify-ratio) * 1.5* var(--clamp-8-16));
    margin-bottom: calc(var(--amplify-ratio) * var(--clamp-8-16));
    display: block;
    border: none;
    background: #FFFFFF 0% 0% no-repeat padding-box;
    border-radius: calc(var(--amplify-ratio) * var(--clamp-16-32));
    width: 100%;
    font-weight: 500;

}
.profile-modal form .profile-modal-input::placeholder {
    opacity: .5;
    font-weight: 600;

}
.profile-modal form p {
    margin-bottom: .25rem;
    margin-right: 7ch;
    white-space: nowrap;
    font-weight: 500;
    margin-left: 1ch;
}

.profile-modal form p::before {
    margin-left: -1ch;
    content: "* ";
    color: var(--background-primary-color);
    font-size: calc(1.5 * var(--amplify-ratio) * var(--clamp-8-16));
    font-weight: 600;
}

.profile-modal form .profile-modal-submit-container{
    text-align: right;
}
.profile-modal form .profile-modal-submit {
    --clamp-7-14: clamp(0.4375rem, 0.3313106796116505rem + 0.45307443365695793vw, 0.875rem);
    font-size: calc(var(--amplify-ratio) * var(--clamp-7-14));
    font-weight: 500;
    background-color: var(--background-primary-color);
    padding: calc(var(--amplify-ratio) * var(--clamp-8-16)) calc(var(--amplify-ratio)* var(--clamp-35-70));
    border-radius: calc(var(--amplify-ratio) * var(--clamp-16-32));
    text-align: right;
    display: inline-block;
    margin-left: auto;
    border: none;
    color: #fff;
    cursor: pointer;
}
/* .profile-modal.student .profile-modal-submit {
    background-color: var(--light-burble-1);
} */