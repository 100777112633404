.pen-icon-image {
  width: auto;
  height: 25px;
  padding-left: 0.5rem;
  display: inline-flex;
  align-items: center;
  justify-content: flex-end;
  margin-left: auto;

  cursor: pointer;
}

.pen-icon-image img {
  height: 70%;
  width: auto;
}
@media (min-width: 1250px) {
  .pen-icon-image {
    margin-left: auto;
    padding-left: 2rem;
  }
  .pen-icon-image img {
    height: 80%;
  }
}
