.news-container > a {
  text-decoration: none;
}
.news-container .news-element > .attachment-container {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  width: 100%;
  aspect-ratio: 4/3;
  overflow: hidden;
  margin-bottom: clamp(
    1rem,
    0.620752427184466rem + 1.6181229773462782vw,
    2.5625rem
  );
}
.news-container .news-element > .attachment-container .attachment {
  max-width: 100%;
  width: 100%;
  display: inline;
  vertical-align: middle;
  margin: 0;
  height: 100%;
  padding: 0;
  object-fit: cover;
  /* aspect-ratio: 1.5; */
}
.news-container
  .news-element
  > .attachment-container
  .attachment
  .attachment-page
  > .small
  .react-pdf__Page__canvas {
  width: 100% !important;
  height: 100% !important;
  border: none;
  border-radius: 0;
}
/* .news-container  .news-element > .attachment-container iframe.attachment {
    width: 100%;
  height: 100%;
  overflow: hidden;
  } */
/* .news-container>.news-element>.attachment-container >iframe.attachment * {
  overflow: hidden;
}  */
.news-container .news-element > h2 {
  color: #151515;
  font-family: var(--font-family-2);
  font-weight: bold;
  font-size: var(--font-size-21);
  font-size: var(--font-size-21-32);
  margin: 0;
  padding: 0;
  padding-bottom: clamp(
    0.5rem,
    0.31796116504854366rem + 0.7766990291262136vw,
    1.25rem
  );
  margin-bottom: clamp(
    0.5rem,
    0.4696601941747573rem + 0.12944983818770225vw,
    0.625rem
  );
  text-align: center;
  border-bottom: solid #681e46 1px;
}

.news-container .news-element > .author {
  color: #151515;
  opacity: 50%;
  font-family: var(--font-family-1);
  font-weight: normal;
  font-size: 0.5rem;
  font-size: var(--font-size-8);
  font-size: var(--font-size-8-12);
  margin: 0;
  padding: 0;
  margin-bottom: 0.5rem;
}
.news-container .news-element > .summary {
  color: #151515;
  font-family: var(--font-family-1);
  font-size: var(--font-size-14);
  font-size: var(--font-size-14-20);
}
