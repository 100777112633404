.dropdownButton,
.dropdownButton:focus,
.dropdownButton:hover,
.dropdownButton:active,
.dropdownButton button:focus,
.dropdownButton button:hover,
.dropdownButton button:active {
  outline: none !important;
  box-shadow: none !important;
  border: 1px solid #f5f5f55e !important;
  border-radius: 7px !important;
}

.dropdownButton {
  background-color: #681e460d !important;
}
.dropdown-toggle::after {
  display: inline-block;
  margin-left: 10px !important;
  vertical-align: 2px !important;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
  color: #8e8c8c !important;
}
.dropdown-menu {
  padding: 10px 0 !important;
  overflow-y: auto !important;
}

.dropdown-item {
  padding: 5px 0px !important;
  text-align: center !important;
  color: #151515 !important;
  font-size: 14px;
  text-transform: capitalize;
}
.dropdown-menu::-webkit-scrollbar {
  width: 7px;
}
.dropdown-item:focus,
.dropdown-item:hover {
  color: #151515 !important;
  background-color: #c1c1c1 !important;
}
/* Track */
.dropdown-menu::-webkit-scrollbar-track {
  background: #f5f5f55e;
}

/* Handle */
.dropdown-menu::-webkit-scrollbar-thumb {
  background: #dddddd;
}
.form-label {
  color: #8e8c8c !important;
  font-weight: 700;
}
.LoaderMainContainer {
  z-index: 99999;
  width: 100%;
  position: absolute;
  left: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #681e46;
  height: 100%;
}
.loader-container {
  position: relative;
  width: 18rem;
  height: 18rem;
  background-color: #ffffff;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loader-container .dot {
  position: absolute;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  background-image: none;
}

.loader-container .dot-1 {
  background-color: #681e46;
  z-index: 4;
  animation: moveDot1 2s infinite ease;
}

.loader-container .dot-2 {
  background-color: #cc006e;
  z-index: 3;
  animation: moveDot2 2s infinite ease;
}

.loader-container .dot-3 {
  background-color: #13030c;
  z-index: 2;
  animation: moveDot3 2s infinite ease;
}

.loader-container .dot-4 {
  background-color: #3d2c36;
  z-index: 1;
  animation: moveDot4 2s infinite ease;
}

@keyframes moveDot1 {
  from {
    transform: rotate(0deg);
    /* transform-origin: top left */
    transform-origin: -20px -20px;
    transform-style: preserve-3D;
  }

  to {
    transform: rotate(-360deg);
    /* transform-origin: top left */
    transform-origin: -20px -20px;
    transform-style: preserve-3D;
  }
}

@keyframes moveDot2 {
  from {
    transform: rotate(0deg);
    /* transform-origin: top right */
    transform-origin: 40px -20px;
    transform-style: preserve-3D;
  }

  to {
    transform: rotate(-360deg);
    /* transform-origin: top right */
    transform-origin: 40px -20px;
    transform-style: preserve-3D;
  }
}

@keyframes moveDot3 {
  from {
    transform: rotate(0deg);
    /* transform-origin: bottom left */
    transform-origin: -20px 40px;
    transform-style: preserve-3D;
  }

  to {
    transform: rotate(-360deg);
    /* transform-origin: bottom left */
    transform-origin: -20px 40px;
    transform-style: preserve-3D;
  }
}

@keyframes moveDot4 {
  from {
    transform: rotate(0deg);
    /* transform-origin: bottom right */
    transform-origin: 40px 40px;
    transform-style: preserve-3D;
  }

  to {
    transform: rotate(-360deg);
    /* transform-origin: bottom right */
    transform-origin: 40px 40px;
    transform-style: preserve-3D;
  }
}

/* ------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------- */
/* ------------------------------------------------------------------------- */

@media (max-width: 768px) {
  .loader-container {
    width: 150px;
    height: 150px;
  }
  .loader-container .dot {
    width: 10px;
    height: 10px;
  }

  @keyframes moveDot1 {
    from {
      transform: rotate(0deg);
      /* transform-origin: top left */
      transform-origin: -10px -10px;
      transform-style: preserve-3D;
    }

    to {
      transform: rotate(-360deg);
      /* transform-origin: top left */
      transform-origin: -10px -10px;
      transform-style: preserve-3D;
    }
  }

  @keyframes moveDot2 {
    from {
      transform: rotate(0deg);
      /* transform-origin: top right */
      transform-origin: 20px -10px;
      transform-style: preserve-3D;
    }

    to {
      transform: rotate(-360deg);
      /* transform-origin: top right */
      transform-origin: 20px -10px;
      transform-style: preserve-3D;
    }
  }

  @keyframes moveDot3 {
    from {
      transform: rotate(0deg);
      /* transform-origin: bottom left */
      transform-origin: -10px 20px;
      transform-style: preserve-3D;
    }

    to {
      transform: rotate(-360deg);
      /* transform-origin: bottom left */
      transform-origin: -10px 20px;
      transform-style: preserve-3D;
    }
  }

  @keyframes moveDot4 {
    from {
      transform: rotate(0deg);
      /* transform-origin: bottom right */
      transform-origin: 20px 20px;
      transform-style: preserve-3D;
    }

    to {
      transform: rotate(-360deg);
      /* transform-origin: bottom right */
      transform-origin: 20px 20px;
      transform-style: preserve-3D;
    }
  }
}
