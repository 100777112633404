.contact-us > .artis-location {
  margin: 0;
  padding: clamp(1.5rem, 1.175rem + 1.625vw, 3.125rem);
  background: rgba(240, 181, 198, 0.6);
  display: flex;
  gap: clamp(1.5rem, 0.34708737864077666rem + 4.919093851132686vw, 6.25rem);
  flex-direction: column;
}
.contact-us > .artis-location > picture {
  width: 100;
  margin: 0;
  padding: 0;
  position: relative;
  height: fit-content;
  display: block;
}

.contact-us > .artis-location > picture > img {
  width: 100%;
  height: auto;
}

.contact-us > .artis-location > picture::after {
  content: "Location";
  position: absolute;
  inset: auto 0 0;
  background: rgba(245, 200, 213, 0.8) 0% 0% no-repeat padding-box;
  color: var(--primary-color);
  vertical-align: middle;
  font-family: var(--font-family-1);
  font-weight: 500;
  font-size: 0.5625rem;
  font-size: clamp(0.5625rem, 0.27499999999999997rem + 1.4375vw, 2rem);
  padding: clamp(
      0.5rem,
      0.32499999999999996rem + 0.8750000000000001vw,
      1.375rem
    )
    0;
  text-align: center;
}

.locationIFrame {
  max-width: 100%;
}

.contact-us > .artis-location > .opening-hours > h2 {
  font-family: var(--font-family-2);
  font-size: var(--font-size-21);
  font-size: calc(1.5 * var(--font-size-21-56));
  padding: 0;
  margin: 0;
  color: var(--primary-color);
}
.contact-us > .artis-location > .opening-hours > p {
  font-family: var(--font-family-1);
  color: var(--primary-color);
  font-size: var(--font-size-12);
  font-size: calc(1.5 * var(--font-size-12-32));
  padding: 0;
  margin-top: clamp(
    0.375rem,
    0.19999999999999998rem + 0.8750000000000001vw,
    1.25rem
  );
  margin-bottom: clamp(0.5rem, 0.22499999999999998rem + 1.375vw, 1.875rem);
}
@media (min-width: 1066px) {
  .contact-us > .artis-location {
    justify-content: space-between;
    align-items: center;
    flex-direction: row;
  }

  .contact-us > .artis-location > picture {
    width: 50%;
  }

  .contact-us > .artis-location > .opening-hours > p {
    font-size: 1rem;
    font-size: clamp(1rem, -0.2482435597189696rem + 1.873536299765808vw, 2rem);
  }

  .contact-us > .artis-location > .opening-hours > h2 {
    font-size: 2rem;
    font-size: clamp(2rem, 0.1276346604215457rem + 2.810304449648712vw, 3.5rem);
  }
}
