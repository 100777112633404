.iconWrapper {
  width: 21%;
  background-color: rgba(221, 197, 255, 0.8);
  display: flex;
  flex-flow: column-reverse nowrap;
  align-items: center;
  justify-content: center;
  font-size: 28px;
  border-radius: 5px;
  padding: 5px;
  text-align: center;
}

@media (max-width: 700px) {
  .iconWrapper {
    width: 30%;
  }
}

@media (max-width: 500px) {
  .iconWrapper {
    width: 40%;
    font-size: 25px;
  }
}

@media (max-width: 420px) {
  .iconWrapper {
    width: 40%;
    font-size: 20px;
  }
}
