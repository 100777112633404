.private-instructions {
  position: relative;
  width: 100%;
  overflow-x: hidden;
}
.private-instructions-container {
  background: transparent
    linear-gradient(
      181deg,
      hsl(352, 64%, 68%) 0%,
      #f6f0ee 45%,
      #f6f0ee 54%,
      #ff9ed2 100%
    )
    0% 0% no-repeat padding-box;
}
.private-instructions .home-page-container {
  width: 100%;
  overflow: unset;
}

.private-instructions .image-orchestra {
  --image-width: clamp(
    8.75rem,
    5.564320388349515rem + 13.592233009708737vw,
    21.875rem
  );
  width: var(--image-width);
  height: auto;
  position: absolute;
  top: clamp(7.5rem, 4.996966019417476rem + 10.679611650485437vw, 17.8125rem);
  left: calc(var(--image-width) * -0.2);
  z-index: 1;
}
.private-instructions .image-piano {
  --image-width: clamp(
    13.4375rem,
    8.355582524271846rem + 21.68284789644013vw,
    34.375rem
  );
  width: var(--image-width);
  left: calc(var(--image-width) * -0.2);
  height: auto;
  position: absolute;
  top: 37%;
}
.private-instructions .image-guitar-1 {
  --image-width: clamp(
    10.25rem,
    5.152912621359223rem + 21.74757281553398vw,
    31.25rem
  );
  width: var(--image-width);
  height: auto;
  position: absolute;
  top: clamp(15.875rem, 13.96359223300971rem + 8.155339805825243vw, 23.75rem);
  right: calc(var(--image-width) * -0.2);
}
.private-instructions .image-guitar-2 {
  --image-width: clamp(
    31.25rem,
    20.631067961165048rem + 45.307443365695796vw,
    75rem
  );
  width: var(--image-width);
  height: auto;
  position: absolute;
  top: 41%;
  right: calc(var(--image-width) * -0.5);
}

.privateInstructionsGeneralIcons {
  width: 30px;
  height: 30px;
}

@media (min-width: 1066px) {
  .private-instructions .image-orchestra {
    left: auto;
    right: calc(var(--image-width) * 0.32);
    top: calc(clamp(2.75rem, 11.73vw, 8.19rem) * 1.3);
  }

  .private-instructions .image-guitar-1 {
    right: auto;
    left: 27%;
    top: calc(clamp(2.75rem, 11.73vw, 8.19rem) * 1.2);
  }

  .private-instructions .image-guitar-2 {
    top: 30%;
    right: calc(var(--image-width) * -0.41);
  }
}
