.profile-page .profile-details {
  font-family: var(--font-family-1);
  font-size: 1rem;
  font-weight: 400;
  margin: 0 auto;
  width: max-content;
  max-width: 100%;
  overflow: auto;
  position: relative;
}

.profile-page .profile-details .profile-detail-element {
  display: grid;
  grid-template-columns: 2fr 3fr;
  gap: 0.5rem;
  margin-bottom: var(--clamp-16-32);
}

.profile-page .profile-details .profile-detail-element h5 {
  color: #4b4b4b;
  font-weight: 600;
  font-size: var(--clamp-16-32);
}

.profile-page .profile-details .profile-detail-element h5::after {
  content: " :";
  font-weight: 600;
}

.profile-page .profile-details .profile-detail-element h4 {
  font-size: var(--clamp-16-32);
  display: flex;
}

@media (min-width: 1400px) {
  .profile-page .profile-details {
    height: 80.2%;
    margin: 0;
    padding: 0;
    border: #681e46 solid 1px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: var(--clamp-19-38);
  }

  .profile-page.student .profile-details.student {
    border: #f27180 solid 1px;
  }

  .profile-page .profile-details-grid {
    vertical-align: middle;

    display: grid;
    grid-template-columns: 1fr 1fr;
    margin: auto;
    row-gap: var(--clamp-0-24);
    grid-column-gap: 20px;
  }

  .profile-page.student .profile-details-grid {
    column-gap: clamp(2rem, 1.5145631067961165rem + 2.071197411003236vw, 4rem);
    /* row-gap: 0; */
    row-gap: calc(0.75 * var(--clamp-0-24));
  }

  .profile-page .profile-details .profile-detail-element {
    display: block;
    margin: 0;
  }

  /* .profile-page .profile-details .profile-detail-element.contact-name {
            grid-column-start: 1;
    
        } */

  .profile-page .profile-details .profile-detail-element.home-address {
    grid-column: span 2;
  }

  .profile-page .profile-details .profile-detail-element h5 {
    font-size: var(--clamp-7-14);
    color: var(--light-gray-color-1);

    margin-bottom: 0.5rem;
  }

  .profile-page .profile-details .profile-detail-element h5::after {
    display: none;
    content: "";
  }

  .profile-page .profile-details .profile-detail-element h4 {
    font-size: var(--clamp-8-16);
    display: flex;
  }
}

@media (max-width: 450px) {
  .profile-page .profile-details .profile-detail-element {
    grid-template-columns: 1fr;
  }
}

.instruction {
  display: block;
  margin: 12px 0 24px 0;

  font-size: 18px;
  color: red;
}

@media screen and (min-width: 1400px) {
  .instruction {
    position: absolute;
    top: 10%;
    margin: 0;
  }
}
