
.artis-calendar > a {
  text-decoration: none;
  margin: 1.5rem 0;
  background-color: var(--background-secondary-color);
  box-shadow: 0px 12px 14px #81299099;
  color: #fff;
  font-size: 0.75rem;
  width: fit-content;
  line-height: 0.9rem;
  padding: 0.9rem 1.5rem;
  font-weight: 400;
  cursor: pointer;
  display: block;
}

.artis-calendar img {
  width: 100%;
}
.artis-calendar.active img {
  height: 100%;
  width: auto;
}
@media (min-width: 1066px) {
  .artis-calendar {
  }
 

  .artis-calendar > a {
    font-size: 1.5rem;
    margin-top: 2rem;
    padding: 1.5rem 2rem;
    line-height: 2rem;
  }
}

@media (min-width: 1300px) {
  /* .artis-calendar > .second-heading::before {
    content: "Events ";
  } */
}
