.contact-us-container {
  background: rgba(244, 206, 217, 1);
}

main.contact-us {
  padding: clamp(
      2.8125rem,
      2.1298543689320386rem + 2.912621359223301vw,
      5.625rem
    )
    clamp(2.5rem, 0.9830097087378642rem + 6.472491909385113vw, 8.75rem);
}
main.contact-us > h2 {
  color: #812990;
  font-family: var(--font-family-2);
  font-weight: bold;
  font-size: 2.125rem;
  font-size: clamp(
    2.125rem,
    1.366504854368932rem + 3.2362459546925564vw,
    5.25rem
  );
  margin: 0;

  margin-bottom: clamp(
    1.5rem,
    0.8780339805825242rem + 2.6537216828478964vw,
    4.0625rem
  );
}
