.music-recitals * {
  margin: 0;
  padding: 0;
}

.music-recitals {
  padding: var(--section-padding);
}
.music-recitals > p {
  color: #000;
  font-family: var(--font-family-1);
  font-size: 0.75rem;
  font-size: var(--font-size-12-24);
}

