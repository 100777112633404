.extendedContentButton {
  padding: 10px;
  border-radius: 5px;
  background-color: blueviolet;
  border: 1px solid blueviolet;
  color: white;
  text-decoration: none;
  transition: all 0.5s ease;
}

.extendedContentButton:hover {
  background-color: rgb(107, 0, 206);
  border: 1px solid rgb(73, 50, 95);
  color: rgb(255, 220, 235);
}

.raisingTalentsSpan {
  padding: 0px 10px;
  align-self: flex-end;

  color: brown;
  border-radius: 10px;
  background-color: #dfd981;
}

.raisingTalentIcon {
  width: 40px;
  height: 40px;
}

@media (max-width: 1290px) {
  .teamMemberCard {
    width: 45%;
    border-radius: 5px;
    padding: 10px;
  }
}

@media (max-width: 870px) {
  .teamMemberCard {
    width: 80%;
  }
}

@media (max-width: 440px) {
  .teamMemberCard {
    width: 90%;
  }
}
