.sub-gallery {
  width: 100%;
  margin-bottom: 0;
  max-width: 100vw;
  overflow: hidden;
  display: flex;
}
.sub-gallery picture {
  width: 50%;
  max-width: 50%;
}
.sub-gallery img {
  width: 100%;
  height: auto;
}

@media (min-width: 1066px) {
}
