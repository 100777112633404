.news-loader-container{
  display: flex;
  justify-content: center;
  align-items: center;

  --padding-var:clamp(20rem, 50vh, 50rem);
  padding: var(--padding-var) 0;
}
.news-container {
  padding: clamp(
      2.8125rem,
      2.1298543689320386rem + 2.912621359223301vw,
      5.625rem
    )
    clamp(2.5rem, 0.9830097087378642rem + 6.472491909385113vw, 8.75rem);
  padding-top: 0;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(23rem, 1fr));
  gap: clamp(3.125rem, 2.366504854368932rem + 3.2362459546925564vw, 6.25rem);
  justify-content: space-between;
}
.artis-news > .news-container-header {
  color: #812990;
  font-family: var(--font-family-2);
  font-weight: bold;
  font-size: 2.125rem;
  font-size: clamp(
    2.125rem,
    1.366504854368932rem + 3.2362459546925564vw,
    5.25rem
  );
  margin: 0;
  padding: clamp(
      2.8125rem,
      2.1298543689320386rem + 2.912621359223301vw,
      5.625rem
    )
    clamp(2.5rem, 0.9830097087378642rem + 6.472491909385113vw, 8.75rem);
  padding-bottom: 0;
  margin-bottom: clamp(
    1.5rem,
    0.8780339805825242rem + 2.6537216828478964vw,
    4.0625rem
  );
}

.artis-news > .older-entries {
  color: #151515;
  font-family: var(--font-family-2);
  font-weight: bold;
  margin: 0;
  padding: 0;
  font-size: 1.3125rem;
  font-size: clamp(1.3125rem, 1rem + 1.5625vw, 2.875rem);
  height: fit-content;
  text-decoration: none;
  margin-left: clamp(
    2.5rem,
    0.9830097087378642rem + 6.472491909385113vw,
    8.75rem
  );
  display: flex;
  align-items: center;
}
.artis-news > .older-entries > img {
  width: clamp(0.8125rem, 0.5375rem + 1.375vw, 2.1875rem);
  height: auto;
  margin: 0;
  margin-right: 1rem;
  transform: rotate(-90deg);
}
